<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">Enduring Guardians</h2>
      <p>
        It is important that you trust the person you appoint as your Enduring
        Guardian to make appropriate lifestyle decisions on your behalf. It is
        recommended you inform this person of your wishes about lifestyle
        decisions and involve them in discussions about your views or goals. If
        these change, it is important to let your Enduring Guardian know.
      </p>
      <p>
        An Enduring Guardian must be at least 18 years of age and not have any
        connection with those who provide you with accommodation, healthcare or
        services for a fee.
      </p>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Instructions</div>
          <p>Add up to 4 people as your Enduring Guardians.</p>
          <p>
            If you appoint more than one Enduring Guardian, you must select
            whether the Enduring Guardians are to act
            <span
              class="definition-link"
              @click="openExample('jointly')"
            >
              jointly</span
            >,
            <span
              class="definition-link"
              @click="openExample('severally')"
            >
              severally</span
            >
            or
            <span
              class="definition-link"
              @click="openExample('jointlySeverally')"
            >
              jointly and severally</span
            >.
          </p>
          <p>
            If you appoint multiple Enduring Guardians jointly, you must select
            whether you wish the appointment to be terminated if an Enduring
            Guardian dies, resigns or becomes incapacitated.
          </p>
        </div>
      </notification-box>

      <h3 class="mb-4">Your Enduring Guardians:</h3>

      <transition
        name="component-fade"
        mode="out-in"
      >
        <div
          v-if="!localFormatted.guardians.length"
          class="text-no-result"
          key="empty"
        >
          - Add at least one Enduring Guardian -
        </div>
        <div
          key="list"
          v-else
        >
          <transition-group
            name="component-fade"
            mode="out-in"
          >
            <item-person
              v-for="(item, index) in localFormatted.guardians"
              :key="item + index"
              class="mb-2"
              :id="item"
              :editable="editable"
              type="person"
              @remove="removeGuardian(index)"
            >
              <template v-slot:details="{ entity }">
                <div
                  v-if="entity.type === 'person'"
                  class="text-sm text-cutoff opacity-70 pt-1"
                >
                  <span
                    v-if="!allRequiredFieldsComplete(entity)"
                    class="font-italic"
                    >- Information required -</span
                  >
                  <span v-else>
                    {{ entity.address }}
                  </span>
                </div>
              </template>
            </item-person>
          </transition-group>
        </div>
      </transition>

      <div class="text-label my-4">Add an Enduring Guardian:</div>
      <div class="mb-2">
        <btn-add-large
          label="Add person"
          class="mb-2"
          @click="openDrawerPerson = true"
          @clickDisabled="disabledMessage()"
          :disabled="isMaximumSelected"
        />
      </div>
      <v-input
        :error-messages="
          showErrors.guardians && errorMessages.guardians
            ? errorMessages.guardians
            : null
        "
      />

      <v-expand-transition>
        <div
          v-if="this.local.guardians.length > 1"
          class="mb-4"
        >
          <h3 class="mb-4">
            How would you like to your Enduring Guardians to work together?
          </h3>
          <item-select
            v-for="(item, index) in appointmentOptions"
            :key="item.key"
            :value="item.key === localFormatted.appointment"
            @select="selectAppointmentOption(item.key)"
            :title="item.label"
            :text="item.text"
            :class="{ 'mb-2': index + 1 < appointmentOptions.length }"
          />
          <v-input
            :error-messages="
              showErrors.appointment && errorMessages.appointment
                ? errorMessages.appointment
                : null
            "
          />
        </div>
      </v-expand-transition>

      <v-expand-transition>
        <div
          v-if="
            this.local.guardians.length > 1 &&
            (local.appointment === 'jointly' ||
              local.appointment === 'jointly-severally')
          "
          class="mb-4"
        >
          <h3 class="mb-4">
            Would you like the appointment terminated if one of your Enduring
            Guardians dies, resigns or becomes incapacitated?
          </h3>
          <item-select
            v-for="(item, index) in appointmentTerminationOptions"
            :key="item.key"
            :value="item.key === localFormatted.appointmentTermination"
            @select="selectAppointmentTerminationOption(item.key)"
            :title="item.label"
            :text="item.text"
            :class="{
              'mb-2': index + 1 < appointmentTerminationOptions.length,
            }"
          />
          <v-input
            :error-messages="
              showErrors.appointmentTermination &&
              errorMessages.appointmentTermination
                ? errorMessages.appointmentTermination
                : null
            "
          />
        </div>
      </v-expand-transition>

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
    <drawer
      :open.sync="openDrawerPerson"
      title="Add an Enduring Guardian"
    >
      <drawer-person-select
        v-if="openDrawerPerson"
        @select="addGuardian"
        :exclude="excludePeople"
        :editable="{ ...editable, name: false }"
        :mandatory="required"
      />
    </drawer>

    <drawer
      :open.sync="showDefinitions"
      title="Definitions"
      class="faq-drawer"
    >
      <div
        v-if="showJointly"
        class="pa-4"
      >
        <div class="text-lg font-weight-bold mb-4">Jointly</div>
        <p>
          This means the Enduring Guardians must agree and make all decisions
          together. If you appoint your Enduring Guardians to act jointly, you
          need to specify if:
        </p>
        <ol style="list-style: lower-roman">
          <li>
            your Enduring Guardianship appointment will terminate in the event
            of one of your Enduring Guardians dying, resigning or becoming
            incapacitated; or
          </li>
          <li>the remaining Enduring Guardian(s) are to continue acting.</li>
        </ol>
      </div>
      <div
        v-if="showSeverally"
        class="pa-4"
      >
        <div class="text-lg font-weight-bold mb-4">Severally</div>
        <p>
          This means the Enduring Guardians can make decisions independently of
          each other. If you appoint your Enduring Guardians to act severally,
          your Enduring Guardianship appointment will continue if one of your
          Enduring Guardians dies, resigns or becomes incapacitated.
        </p>
      </div>
      <div
        v-if="showJointlySeverally"
        class="pa-4"
      >
        <div class="text-lg font-weight-bold mb-4">Jointly and Severally</div>
        <p>
          This means the Enduring Guardians can make decisions either together
          or separately. If you appoint your Enduring Guardians to act jointly
          and severally, you need to specify if:
        </p>
        <ol style="list-style: lower-roman">
          <li>
            your Enduring Guardianship appointment will terminate in the event
            of one of your Enduring Guardians dying, resigning or becoming
            incapacitated; or
          </li>
          <li>the remaining Enduring Guardian(s) are to continue acting.</li>
        </ol>
      </div>
    </drawer>
  </div>
</template>

<script>
import { nsw_eg_required } from '../../../../../../config/poaRequiredFields'
import entitiesForms from '../../../mixins/entitiesForms.js'
import forms from '../../../mixins/forms.js'
import {
  appointmentOptions,
  appointmentTerminationOptions,
} from '../../egLists'

const storeName = 'poa_nsw_eg'
const documentId = 'poaNswEg'

export default {
  name: 'AppointGuardians',
  mixins: [
    forms(storeName, documentId),
    entitiesForms('guardians', 'Maximum of 4 guardians.'),
  ],
  data: () => ({
    showErrors: {
      guardians: false,
      appointment: false,
      appointmentTermination: false,
    },
    editable: { address: true, occupation: true, phone: true },
    required: nsw_eg_required,
    appointmentOptions: appointmentOptions,
    appointmentTerminationOptions: appointmentTerminationOptions,
    showDefinitions: false,
    showJointly: false,
    showSeverally: false,
    showJointlySeverally: false,
  }),
  methods: {
    addGuardian(id) {
      this.setAllShowErrors(false)
      this.local.guardians.push(id)
      this.openDrawerPerson = false
    },
    removeGuardian(index) {
      this.setAllShowErrors(false)
      this.$delete(this.local.guardians, index)
    },
    selectAppointmentOption(option) {
      this.setAllShowErrors(false)
      this.local.appointment = option
    },
    selectAppointmentTerminationOption(option) {
      this.setAllShowErrors(false)
      this.local.appointmentTermination = option
    },
    openExample(type) {
      this.showJointly = false
      this.showSeverally = false
      this.showJointlySeverally = false
      this.showDefinitions = true
      this.showJointly = type === 'jointly'
      this.showSeverally = type === 'severally'
      this.showJointlySeverally = type === 'jointlySeverally'
    },
  },
  computed: {
    excludePeople() {
      return [
        ...this.childEntities,
        ...this.localFormatted.guardians,
        this.local.substitute,
      ]
    },
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (!this.localFormatted.guardians.length)
        msgs.guardians = 'Select at least one guardian.'
      if (this.localFormatted.guardians.length > 4)
        msgs.guardians = 'Maximum of 4 guardians.'
      if (
        this.localFormatted.guardians.length > 1 &&
        !this.localFormatted.appointment
      )
        msgs.appointment = 'Required'
      if (
        this.localFormatted.guardians.length > 1 &&
        (this.localFormatted.appointment === 'jointly' ||
          this.localFormatted.appointment === 'jointly-severally') &&
        !this.localFormatted.appointmentTermination
      )
        msgs.appointmentTermination = 'Required'
      return msgs
    },
    localFormatted() {
      let appointment = this.local?.appointment || null
      let appointmentOption = appointment
        ? this.appointmentOptions.find((o) => o.key === appointment) || null
        : null
      let appointmentTermination = this.local?.appointmentTermination || null
      let appointmentTerminationOption = appointmentTermination
        ? this.appointmentTerminationOptions.find(
            (o) => o.key === appointmentTermination
          ) || null
        : null
      return {
        guardians: this.local.guardians || [],
        appointment:
          this.local.guardians.length <= 1
            ? null
            : appointmentOption
              ? appointment
              : null,
        appointmentTermination:
          this.local.guardians.length <= 1
            ? null
            : appointment !== 'jointly' && appointment !== 'jointly-severally'
              ? null
              : appointmentTerminationOption
                ? appointmentTermination
                : null,
      }
    },
  },
}
</script>
