<template>
  <div>
    <div>
      <section-item
        @edit="open('appoint-guardians')"
        class="mb-1"
      >
        <div class="text-label mb-2">Enduring guardians</div>
        <ul
          v-if="guardians.length"
          class="mt-2"
        >
          <li
            v-for="item in guardians"
            :key="item.id"
            class="mb-1"
          >
            {{ item.name }}
            <chip-required
              v-if="!item.address || !item.address.trim().length"
              text="Address required"
              @click="openEditPerson(item.id)"
              :class="{ shake: shake }"
            />
          </li>
        </ul>
        <div v-else>
          <chip-required
            :class="{ shake: shake }"
            @click="open('appoint-guardians')"
          />
          <div class="text-no-result text-left">
            Click 'Edit' on the right to add at least 1 guardian.
          </div>
        </div>
      </section-item>

      <section-item
        @edit="open('substitute-guardians')"
        :disabled="local.guardians.length"
        @clickDisabled="showSubstituteDisabledMsg = true"
        class="mb-1"
      >
        <div class="text-label mb-2">Substitute guardians</div>
        <ul
          v-if="substitutes.length"
          class="mt-2"
        >
          <li
            v-for="item in substitutes"
            :key="item.id"
            class="mb-1"
          >
            {{ item.name }}
            (in substitution of <i>{{ item.inSubstitutionOf.name }}</i
            >)
            <chip-required
              v-if="!item.address || !item.address.trim().length"
              text="Address required"
              @click="openEditPerson(item.id)"
              :class="{ shake: shake }"
            />
          </li>
        </ul>
        <div v-else>
          <div class="text-no-result text-left">
            Click 'Edit' on the right to optionally add substitute guardians.
          </div>
        </div>
      </section-item>
      <transition
        name="component-fade"
        mode="out-in"
      >
        <v-input
          class="-mt-2 mb-2"
          v-if="showSubstituteDisabledMsg && !local.guardians.length"
          :error-messages="
            showSubstituteDisabledMsg && !local.guardians.length
              ? `You must add at least 1 enduring guardian before
        adding substitute guardians.`
              : null
          "
        />
      </transition>

      <section-item
        @edit="open('functions')"
        class="mb-1"
      >
        <div class="text-label">Functions</div>
        <div
          v-if="!functionsSummary"
          class="text-no-result text-left"
        >
          <chip-required
            :class="{ shake: shake }"
            @click="open('functions')"
          />
          <div class="text-no-result text-left">
            You have not added any functions. Click 'Edit' on the right to do
            so.
          </div>
        </div>
        <div v-else>
          {{ functionsSummary }}
        </div>
      </section-item>

      <section-item
        @edit="open('circumstances')"
        class="mb-1"
      >
        <div class="text-label">Circumstances</div>
        <div
          v-if="!local.circumstances"
          class="text-no-result text-left"
        >
          You have not specified any circumstances. Click 'Edit' on the right to
          do so.
        </div>
        <div
          v-else
          class="clamp-3"
        >
          {{ local.circumstances }}
        </div>
      </section-item>

      <section-item
        @edit="open('directions')"
        class="mb-1"
      >
        <div class="text-label">Directions</div>
        <div
          v-if="!local.directions"
          class="text-no-result text-left"
        >
          You have not specified any directions. Click 'Edit' on the right to do
          so.
        </div>
        <div
          v-else
          class="clamp-3"
        >
          {{ local.directions }}
        </div>
      </section-item>

      <section-item
        @edit="open('advanceHealthDirective')"
        class="mb-1"
      >
        <div class="text-label">Advance Health Directive</div>
        <div
          v-if="local.advanceHealthDirective === null"
          class="text-no-result text-left"
        >
          <chip-required
            :class="{ shake: shake }"
            @click="open('advanceHealthDirective')"
          />
          <div class="text-no-result text-left">
            You have not specified whether you have made an Advance Health
            Directive. Click 'Edit' on the right to do.
          </div>
        </div>
        <div v-else>
          {{
            local.advanceHealthDirective
              ? `I have an Advance Health Directive`
              : `I don't have an Advance Health Directive.`
          }}
        </div>
      </section-item>

      <dialog-full
        :open.sync="dialogOpen"
        title="Enduring Power of Guardianship"
        :path="gaUrl"
      >
        <component
          v-if="openForm"
          :is="`dialog-${openForm}`"
          @close="close"
          @next="next"
        />
      </dialog-full>
    </div>
    <drawer
      title="Edit Person"
      :open.sync="openEdit"
    >
      <div class="pa-4">
        <slot name="form">
          <form-person
            v-if="openEdit"
            :id="editId"
            @close="openEdit = false"
            @next="openEdit = false"
            :editable="editable"
          />
        </slot>
      </div>
    </drawer>
  </div>
</template>

<script>
import { wa_epg_required } from '../../../../config/poaRequiredFields'
import poa from '../mixins/poa.js'
import { appointmentTerminationOptions } from './epgLists'
import DialogAdvanceHealthDirective from './forms/epg/AdvanceHealthDirective.vue'
import DialogAppointGuardians from './forms/epg/AppointGuardians.vue'
import DialogCircumstances from './forms/epg/Circumstances.vue'
import DialogDirections from './forms/epg/Directions.vue'
import DialogFunctions from './forms/epg/Functions.vue'
import DialogSubstituteGuardians from './forms/epg/SubstituteGuardians.vue'

const storeName = 'poa_wa_epg'
const documentDir = 'waEpg'

export default {
  name: 'PoaEnduringPowerOfGuardianship',
  mixins: [poa(storeName)],
  components: {
    DialogAppointGuardians,
    DialogSubstituteGuardians,
    DialogAdvanceHealthDirective,
    DialogFunctions,
    DialogCircumstances,
    DialogDirections,
  },
  data: () => ({
    editable: { address: true },
    required: wa_epg_required,
    appointmentTerminationOptions: appointmentTerminationOptions,
    documentDir: documentDir,
    showSubstituteDisabledMsg: false,
  }),
  methods: {},
  computed: {
    guardians() {
      return this.entities_people.filter((o) =>
        this[storeName].guardians.includes(o.id)
      )
    },
    guardianSuffix() {
      return this.guardians.length === 1 ? '' : 's'
    },
    substitutes() {
      return this[storeName].substituteGuardians.map((o) => ({
        ...this.entities_people.find((e) => e.id === o.entity),
        inSubstitutionOf: this.entities_people.find(
          (e) => e.id === o.inSubstitutionOf
        ),
      }))
    },
    functionsSummary() {
      if (this.local.functionsAuthorisedAll) return 'All functions authorised.'
      if (
        this.local.functions.livingSituation ||
        this.local.functions.livingWith ||
        this.local.functions.medicalTreatment ||
        this.local.functions.educationTraining ||
        this.local.functions.associating ||
        this.local.functions.legalProceedings ||
        this.local.functions.supportServices ||
        this.local.functions.seekingInformation ||
        this.local.functions.other.length !== 0
      )
        return 'Some functions authorised.'
      return null
    },
  },
}
</script>
