<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">Commencement</h2>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Instructions</div>
          <p>
            You may choose when this power of attorney is to commence operation.
            Click the option that corresponds to when you want this power of
            attorney to operate.
          </p>
        </div>
      </notification-box>

      <div class="mb-4">
        <item-select
          :value="localFormatted.commencement === 'immediately'"
          @select="selectCommencementOption('immediately')"
          title="Immediately"
          class="mb-2"
        />
        <item-select
          :value="localFormatted.commencement === 'impaired'"
          @select="selectCommencementOption('impaired')"
          title="Only when I become a person with impaired decision-making capacity"
          class="mb-2"
        />
        <item-select
          :value="localFormatted.commencement === 'from-date'"
          @select="selectCommencementOption('from-date')"
          title="From date"
          class="mb-2"
        >
          <v-expand-transition>
            <div
              v-if="localFormatted.commencement === 'from-date'"
              class="mx-4"
            >
              <h3 class="my-4">
                Please specify when this Enduring Power of Attorney is to
                commence:
              </h3>
              <date-field
                style="margin-bottom: -1.2rem"
                v-bind.sync="fromDate"
                :valid.sync="dateValid"
                :in-future="true"
                ref="dateRef"
              />
              <v-input
                class="mb-1"
                :error-messages="
                  showErrors.commencementDate && errorMessages.commencementDate
                    ? errorMessages.commencementDate
                    : null
                "
              />
            </div>
          </v-expand-transition>
        </item-select>
        <item-select
          :value="localFormatted.commencement === 'from-event'"
          @select="selectCommencementOption('from-event')"
          title="From event"
          class="mb-2"
        >
          <v-expand-transition>
            <div
              v-if="localFormatted.commencement === 'from-event'"
              class="mx-4"
            >
              <h3 class="my-4">
                Please specify when this Enduring Power of Attorney is to
                commence:
              </h3>
              <v-textarea
                :rules="[maxLengthRule]"
                v-bind="textAreaFieldGrow"
                v-model="local.commencementEvent"
                :error-messages="
                  showErrors.commencementEvent &&
                  errorMessages.commencementEvent
                    ? errorMessages.commencementEvent
                    : null
                "
              />
            </div>
          </v-expand-transition>
        </item-select>
        <v-input
          :error-messages="
            showErrors.commencement && errorMessages.commencement
              ? errorMessages.commencement
              : null
          "
        />
      </div>

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'

import DateField from '@/components/fields/DateField'

import forms from '../../../mixins/forms.js'
import { isEmptyTrimmed, trimIfExists } from '../../../utils'

const storeName = 'poa_act_epa'
const documentId = 'poaActEpa'

export default {
  name: 'Commencement',
  mixins: [forms(storeName, documentId)],
  components: {
    DateField,
  },
  data: () => ({
    showErrors: {
      commencement: false,
      commencementDate: false,
      commencementEvent: false,
    },
    fromDate: {
      day: '',
      month: '',
      year: '',
    },
    dateValid: false,
  }),
  mounted() {
    this.local = cloneDeep(this[storeName])
    this.fromDate = this.local.commencementDate
      ? this.local.commencementDate
      : {
          day: '',
          month: '',
          year: '',
        }
  },
  methods: {
    onSubmitFail() {
      if (this.localFormatted.commencement === 'from-date')
        this.$refs.dateRef.setErrorMessage('Please enter a valid date.')
    },
    async save() {
      this.$store.dispatch('account/save_poa', {
        id: documentId,
        data: {
          ...this.localFormatted,
          commencementEvent: trimIfExists(
            this.localFormatted.commencementEvent
          ),
        },
      })
    },
    selectCommencementOption(key) {
      this.setAllShowErrors(false)
      this.local.commencement = key
    },
  },
  computed: {
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (!this.local.commencement)
        msgs.commencement = 'Please select an option'

      if (
        this.localFormatted.commencement === 'from-event' &&
        isEmptyTrimmed(this.local.commencementEvent)
      )
        msgs.commencementEvent = 'Please specify the details of the event.'

      return msgs
    },
    isValid() {
      return (
        Object.values(this.errorMessages).every((val) => val === null) &&
        (this.local.commencement === 'from-date' ? this.dateValid : true) &&
        this.textareaValid
      )
    },
    localFormatted() {
      return {
        commencement: this.local.commencement,
        commencementDate:
          this.local.commencement === 'from-date' ? this.fromDate : null,
        commencementEvent:
          this.local.commencement === 'from-event'
            ? this.local.commencementEvent
            : null,
      }
    },
  },
}
</script>
