<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">Assistance with signing</h2>
      <h3 class="mb-4">
        Do you need someone to sign for you due to a physical disability?
      </h3>

      <div class="toggle-yes-no">
        <item-select
          :value="localFormatted.signatureAssistance"
          @select="setSignatureAssistance(true)"
          title="Yes"
        />
        <item-select
          :value="!localFormatted.signatureAssistance"
          @select="setSignatureAssistance(false)"
          title="No"
        />
      </div>

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import forms from '../../../mixins/forms.js'

const storeName = 'poa_vic_epa'
const documentId = 'poaVicEpa'

export default {
  name: 'SignatureAssistance',
  mixins: [forms(storeName, documentId)],
  data: () => ({
    showErrors: {
      signatureAssistance: false,
    },
  }),
  methods: {
    setSignatureAssistance(value) {
      this.$set(this.local, 'signatureAssistance', value)
    },
  },
  computed: {
    localFormatted() {
      return {
        signatureAssistance: this.local.signatureAssistance,
      }
    },
  },
}
</script>
