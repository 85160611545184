<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">What is important to you after death</h2>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Instructions</div>
          <p>
            It is recommended that you discuss this section with your decision
            maker(s), family or doctor as it is important that anything you
            write should be readily understood by the people who are supporting
            and treating you.
          </p>
        </div>
      </notification-box>

      <h3 class="mb-4">After death, what is important to you?</h3>
      <p>
        For example, a ceremonial smoking, or for my body to be returned to my
        birth country, blessings, cremation, burial etc.
      </p>
      <v-textarea
        :rules="[maxLengthRule]"
        v-bind="textAreaFieldGrow"
        v-model="local.advanceCareStatement.afterDeathImportant"
        :error-messages="
          showErrors.afterDeathImportant && errorMessages.afterDeathImportant
            ? errorMessages.afterDeathImportant
            : null
        "
      />

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import forms from '../../../mixins/forms.js'
import { trimIfExists } from '../../../utils'

const storeName = 'poa_nt_app'
const documentId = 'poaNtApp'

export default {
  name: 'AfterDeath',
  mixins: [forms(storeName, documentId)],
  data: () => ({
    showErrors: {
      afterDeathImportant: false,
    },
  }),
  methods: {
    async save() {
      this.$store.dispatch('account/save_poa', {
        id: documentId,
        data: {
          advanceCareStatement: {
            ...this.localFormatted.advanceCareStatement,
            afterDeathImportant: trimIfExists(
              this.localFormatted.advanceCareStatement.afterDeathImportant
            ),
          },
        },
      })
    },
  },
  computed: {
    localFormatted() {
      return {
        advanceCareStatement: {
          ...this.local.advanceCareStatement,
          afterDeathImportant:
            this.local && this.local.advanceCareStatement.afterDeathImportant
              ? this.local.advanceCareStatement.afterDeathImportant
              : null,
        },
      }
    },
  },
}
</script>
