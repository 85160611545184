<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">CPR</h2>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Instructions</div>
          <p>
            It is strongly recommended that before completing this document you
            discuss your options with your doctor who knows your medical history
            and views. The doctor will also be able to explain any medical terms
            that you are unsure about and will confirm that you were able to
            understand the decisions you have made in the document and that you
            made those decisions voluntarily. You can also ask your doctor to
            witness your signature.
          </p>
          <p>
            It is your legal right to refuse any medical treatment. However, you
            may not be entitled to insist on receiving a particular treatment
            (if, for example, your health-care provider’s professional opinion
            is that the treatment would not be of benefit to you).
          </p>
          <p>
            Cardio Pulmonary Resuscitation (CPR): refers to medical procedures
            that may be used to restart your heart or breathing if they stop due
            to severe illness. It usually involves very strong pumping on your
            chest, electric shocks to your heart, medications injected into your
            veins and breathing tubes being put into your throat to allow a
            machine to breath for you.
          </p>
        </div>
      </notification-box>

      <h3 class="mb-4">If my heart stops and CPR is an option:</h3>

      <item-select
        :value="local.advanceConsentDecision.cpr"
        @select="local.advanceConsentDecision.cpr = true"
        title="Please try to restart my heart or breathing (attempt CPR)"
        text="Except if it results in an unacceptable outcome (specified)."
        class="mb-2"
      >
        <v-expand-transition>
          <div
            v-if="local.advanceConsentDecision.cpr"
            class="mx-4"
          >
            <h4 class="my-4">
              Refer to what you wrote in the "If nearing death, what is
              unacceptable to you" and describe unacceptable outcomes, for
              example, I will not be able to live independently or go home.
            </h4>
            <v-textarea
              :rules="[maxLengthRule]"
              v-bind="textAreaFieldGrow"
              v-model="local.advanceConsentDecision.cprUnacceptableOutcomes"
            />
          </div>
        </v-expand-transition>
      </item-select>
      <item-select
        :value="local.advanceConsentDecision.cpr === false"
        @select="local.advanceConsentDecision.cpr = false"
        title="Do not try to restart my heart or breathing (don't attempt CPR)"
        class="mb-2"
      />
      <v-input
        :error-messages="
          showErrors.cpr && errorMessages.cpr ? errorMessages.cpr : null
        "
      />

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import forms from '../../../mixins/forms.js'
import { trimIfExists } from '../../../utils'

const storeName = 'poa_nt_app'
const documentId = 'poaNtApp'

export default {
  name: 'HeartStops',
  mixins: [forms(storeName, documentId)],
  data: () => ({
    showErrors: {
      cpr: false,
    },
  }),
  methods: {
    async save() {
      this.$store.dispatch('account/save_poa', {
        id: documentId,
        data: {
          advanceConsentDecision: {
            ...this.localFormatted.advanceConsentDecision,
            cprUnacceptableOutcomes: trimIfExists(
              this.localFormatted.advanceConsentDecision.cprUnacceptableOutcomes
            ),
          },
        },
      })
    },
  },
  computed: {
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (this.localFormatted.advanceConsentDecision.cpr === null)
        msgs.cpr = 'Please select an option.'
      return msgs
    },
    localFormatted() {
      return {
        advanceConsentDecision: {
          ...this.local.advanceConsentDecision,
          cpr:
            this.local && this.local.advanceConsentDecision.cpr !== null
              ? this.local.advanceConsentDecision.cpr
              : null,
          cprUnacceptableOutcomes:
            this.local &&
            this.local.advanceConsentDecision.cpr &&
            this.local.advanceConsentDecision.cprUnacceptableOutcomes
              ? this.local.advanceConsentDecision.cprUnacceptableOutcomes
              : null,
        },
      }
    },
  },
}
</script>
