<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">Functions of enduring guardian{{ guardianSuffix }}</h2>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Instructions</div>
          <p>
            Select what functions you wish your Enduring Guardian{{
              guardianSuffix
            }}
            to have.
          </p>
          <p>
            <i>
              Note: An enduring guardian cannot be authorised to make decisions
              about financial or property matters.
            </i>
          </p>
        </div>
      </notification-box>

      <h2 class="my-4">
        Would you like your enduring guardian{{ guardianSuffix }} to have
        authority to exercise all functions?
      </h2>
      <div class="toggle-yes-no">
        <item-select
          :value="this.localFormatted.functionsAuthorisedAll"
          @select="selectFunctionsAuthorisedAll(true)"
          title="Yes"
        />
        <item-select
          :value="this.localFormatted.functionsAuthorisedAll === false"
          @select="selectFunctionsAuthorisedAll(false)"
          title="No"
        />
      </div>

      <div v-if="!this.localFormatted.functionsAuthorisedAll">
        <h3 class="mt-6 mb-4">
          Please select the functions you wish to authorise for:
        </h3>
        <item-select
          v-for="f in predefinedFunctions"
          :key="f.value"
          :value="functionValue(f.value)"
          @select="togglePredefinedFunction(f.value)"
          :title="f.text"
          title-size="text-base"
          class="mb-2"
        />

        <other-function
          v-for="(f, index) in this.localFormatted.functions.other"
          :key="index"
          :value="f"
          @delete="deleteOtherFunction(index)"
          class="mb-2"
        />

        <btn-add-other
          label="Add other function"
          class="mb-2"
          @click="toggleShowAddOtherFunction"
        >
          <v-expand-transition>
            <div
              v-if="showOtherTextbox"
              class="mx-4"
            >
              <h4 class="my-2">Please specify the function</h4>
              <v-textarea
                :rules="[maxLengthRule]"
                v-if="showOtherTextbox"
                v-bind="textAreaFieldGrow"
                v-model="otherTextbox"
                :error-messages="
                  showErrors.otherFunction && errorMessages.otherFunction
                    ? errorMessages.otherFunction
                    : null
                "
              />
              <v-btn
                v-bind="addFunctionButtonType"
                depressed
                right
                class="add-other-function"
                @click="saveOtherFunction"
                >add</v-btn
              >
            </div>
          </v-expand-transition>
        </btn-add-other>
      </div>
      <v-input
        :error-messages="
          showErrors.functions && errorMessages.functions
            ? errorMessages.functions
            : null
        "
      />

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import forms from '../../../mixins/forms.js'
import BtnAddOther from '../../BtnAddOther'
import { predefinedFunctions } from '../../epgLists'
import OtherFunction from '../../OtherFunction'

const storeName = 'poa_wa_epg'
const documentId = 'poaWaEpg'

export default {
  name: 'FunctionsAndLimitations',
  mixins: [forms(storeName, documentId)],
  components: {
    OtherFunction,
    BtnAddOther,
  },
  data: () => ({
    showErrors: {
      functions: false,
      otherFunction: false,
      unsavedOtherFunction: false,
    },
    predefinedFunctions: predefinedFunctions,
    showOtherTextbox: false,
    otherTextbox: '',
  }),
  methods: {
    onSubmitFail() {
      if (this.errorMessages.livingSituation)
        this.$refs.livingSituationSection.$el.scrollIntoView()
    },
    selectFunctionsAuthorisedAll(value) {
      if (this.local) this.local.functionsAuthorisedAll = value
    },
    togglePredefinedFunction(value) {
      if (this.local) this.local.functions[value] = !this.local.functions[value]
    },
    toggleShowAddOtherFunction() {
      if (this.showOtherTextbox) {
        this.otherTextbox = ''
      } else {
        this.showErrorsSwitch(false, 'otherFunction')
        this.showErrorsSwitch(false, 'functions')
      }
      this.showOtherTextbox = !this.showOtherTextbox
    },
    saveOtherFunction() {
      this.showErrorsSwitch(true, 'otherFunction')
      if (this.addFunctionIsValid) {
        this.local.functions.other.push(this.otherTextbox.trim())
        this.otherTextbox = ''
        this.showOtherTextbox = false
      }
    },
    deleteOtherFunction(index) {
      if (this.local) this.local.functions.other.splice(index, 1)
    },
    showErrorsSwitch(show = true, field = null) {
      if (field) {
        this.showErrors[field] = show
        return
      }
      Object.keys(this.showErrors).forEach((key) => {
        this.showErrors[key] = show
      })
    },
  },
  computed: {
    addFunctionButtonType() {
      if (this.addFunctionIsValid) return this.btnActive
      return this.btnInactive
    },
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (
        !this.local.functionsAuthorisedAll &&
        !this.local.functions.livingSituation &&
        !this.local.functions.livingWith &&
        !this.local.functions.medicalTreatment &&
        !this.local.functions.educationTraining &&
        !this.local.functions.associating &&
        !this.local.functions.legalProceedings &&
        !this.local.functions.supportServices &&
        !this.local.functions.seekingInformation &&
        this.local.functions.other.length === 0
      )
        msgs.functions = 'Please select at least one function.'
      if (this.showOtherTextbox && this.otherTextbox === '')
        msgs.otherFunction = 'Please enter a function.'
      if (this.showOtherTextbox && this.otherTextbox !== '')
        msgs.functions =
          'Please add your function or close "Add other function" section.'
      return msgs
    },
    guardianSuffix() {
      return this.local.guardians.length === 1 ? '' : 's'
    },
    isValid() {
      // valid if all error messages are null except for otherFunction
      return (
        Object.values(this.errorMessages).every((val) => {
          if (val === null) return true
          if (val === 'Please enter a function.') return true
          return false
        }) && this.textareaValid
      )
    },
    addFunctionIsValid() {
      return this.otherTextbox !== ''
    },
    functionValue() {
      return (fValue) =>
        this.localFormatted && this.localFormatted.functions
          ? this.localFormatted.functions[fValue]
          : false
    },
    localFormatted() {
      return {
        functionsAuthorisedAll: this.local.functionsAuthorisedAll
          ? true
          : false,
        functions: !this.local.functionsAuthorisedAll
          ? {
              livingSituation: this.local.functions.livingSituation
                ? true
                : false,
              livingWith: this.local.functions.livingWith ? true : false,
              medicalTreatment: this.local.functions.medicalTreatment
                ? true
                : false,
              educationTraining: this.local.functions.educationTraining
                ? true
                : false,
              associating: this.local.functions.associating ? true : false,
              legalProceedings: this.local.functions.legalProceedings
                ? true
                : false,
              supportServices: this.local.functions.supportServices
                ? true
                : false,
              seekingInformation: this.local.functions.seekingInformation
                ? true
                : false,
              other: this.local.functions.other.length
                ? this.local.functions.other
                : [],
            }
          : {
              livingSituation: false,
              livingWith: false,
              medicalTreatment: false,
              educationTraining: false,
              associating: false,
              legalProceedings: false,
              supportServices: false,
              seekingInformation: false,
              other: [],
            },
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.add-other-function {
  margin-bottom: 1rem;
  height: 44px !important;
}
</style>
