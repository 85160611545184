<template>
  <div>
    <p class="mt-4">
      Enduring Power of Attorney and Medical Treatment Decision Maker documents
      give someone you trust the power to make personal, financial and medical
      decisions on your behalf, if you are no longer able to make them for
      yourself.
    </p>
    <p v-if="this.user_access.poaAccessState === 'unpurchased'">
      Having an Enduring Power of Attorney and Medical Treatment Decision Maker
      is crucial to planning ahead in the same way as writing your Will.
    </p>
    <a
      href="https://www.willed.com.au/guides/power-of-attorney-explained/"
      target="_blank"
      class="d-block mb-4"
    >
      More information
    </a>
    <div v-if="this.user_access.poaAccessState !== 'unpurchased'">
      <faqs :faqs="['differenceWillPOA', 'placeKeepPOA']" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Faqs from '../../../components/ui/Faqs'

export default {
  name: 'VicBlurb',
  components: {
    Faqs,
  },
  computed: {
    ...mapGetters('account', ['user_access']),
  },
}
</script>
