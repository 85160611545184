var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":500,"persistent":""},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('v-card',[_c('v-list-item',{staticClass:"bg-sand",attrs:{"dense":""}},[_c('v-list-item-content',[_c('h3',{staticClass:"download-doc-title"},[_vm._v(" Select which document(s) to generate ")])]),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-close")])],1)],1)],1),(_vm.downloadFailed)?_c('div',{staticClass:"text-center pa-4"},[_c('v-alert',{staticStyle:{"margin":"0"},attrs:{"outlined":"","color":"red","icon":"mdi-alert-circle"}},[_c('div',{staticClass:"font-weight-medium"},[_vm._v(" Generation of documents failed. Please try again. If the issue persists, please contact support. ")])])],1):_c('v-list-item',{staticClass:"pa-4"},[_c('v-list-item-content',{staticStyle:{"padding":"0"}},_vm._l((_vm.local),function(doc,index){return _c('item-select',{key:doc.documentDir,staticClass:"select-documents",class:[
            { failed: doc.downloadFailed },
            { invalid: !doc.isValid },
            { 'mb-2': index + 1 < _vm.local.length },
          ],attrs:{"value":doc.toDownload,"title":doc.name,"text":doc.downloaded
              ? 'Document has been downloaded.'
              : doc.downloadFailed
                ? 'Generating document failed, please try again.'
                : doc.generating
                  ? 'Generating...'
                  : doc.token
                    ? 'Ready to download.'
                    : doc.isValid
                      ? 'Complete.'
                      : 'Incomplete, required information is missing.',"disabled":!doc.isValid,"loading":doc.generating,"index":index},on:{"select":function($event){return _vm.toggleDoc(doc)}}},[(doc.token)?_c('div',{staticClass:"px-4 py-3"},[_c('v-btn',{attrs:{"large":"","block":"","loading":false},on:{"click":function($event){return _vm.download(doc)}}},[_c('v-icon',{attrs:{"left":"","color":"primary"}},[_vm._v("mdi-download")]),_vm._v(" Download ")],1)],1):_vm._e()])}),1)],1),_c('div',{staticClass:"divider"}),(!_vm.allDocumentsGenerated)?_c('v-btn',{attrs:{"text":"","tile":"","x-large":"","block":"","download":""},on:{"click":_vm.generateDocuments}},[_vm._v(" Generate document "),_c('span',{style:({ opacity: _vm.multipleSelected ? '1' : '0' })},[_vm._v("s")])]):_vm._e(),_c('div',{staticClass:"divider"}),_c('v-btn',{staticClass:"bg-grey-10",attrs:{"text":"","tile":"","block":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Close")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }