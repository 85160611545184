<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">Functions and limits</h2>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Instructions</div>
          <p>
            Select what functions you wish your Enduring Guardian{{
              guardianSuffix
            }}
            to have should you lose capacity to make decisions yourself.
          </p>
        </div>
      </notification-box>

      <item-select
        :value="local.functions.livingSituation"
        @select="
          local.functions.livingSituation = !local.functions.livingSituation
        "
        title="Decide where I live"
        class="mb-4"
        ref="livingSituationSection"
      >
        <v-expand-transition>
          <div
            v-if="local.functions.livingSituation"
            class="mx-4"
          >
            <h4 class="my-4">
              Would you like to place any limits on this function?
            </h4>
            <div class="toggle-yes-no">
              <item-select
                :value="limitFlags.livingSituation"
                @select="toggleFlag('livingSituation', true)"
                title="Yes"
              />
              <item-select
                :value="!limitFlags.livingSituation"
                @select="toggleFlag('livingSituation', false)"
                title="No"
              />
            </div>
            <v-textarea
              :rules="[maxLengthRule]"
              v-if="limitFlags.livingSituation"
              v-bind="textAreaFieldGrow"
              v-model="local.functions.livingSituationDetails"
              :error-messages="
                showErrors.livingSituation && errorMessages.livingSituation
                  ? errorMessages.livingSituation
                  : null
              "
            />
          </div>
        </v-expand-transition>
      </item-select>

      <item-select
        :value="local.functions.healthCare"
        @select="local.functions.healthCare = !local.functions.healthCare"
        title="Decide what health care I receive"
        class="mb-4"
        ref="healthCareSection"
      >
        <v-expand-transition>
          <div
            v-if="local.functions.healthCare"
            class="mx-4"
          >
            <h4 class="my-4">
              Would you like to place any limits on this function?
            </h4>
            <div class="toggle-yes-no">
              <item-select
                :value="limitFlags.healthCare"
                @select="toggleFlag('healthCare', true)"
                title="Yes"
              />
              <item-select
                :value="!limitFlags.healthCare"
                @select="toggleFlag('healthCare', false)"
                title="No"
              />
            </div>
            <v-textarea
              :rules="[maxLengthRule]"
              v-if="limitFlags.healthCare"
              v-bind="textAreaFieldGrow"
              v-model="local.functions.healthCareDetails"
              :error-messages="
                showErrors.healthCare && errorMessages.healthCare
                  ? errorMessages.healthCare
                  : null
              "
            />
          </div>
        </v-expand-transition>
      </item-select>

      <item-select
        :value="local.functions.personalServices"
        @select="
          local.functions.personalServices = !local.functions.personalServices
        "
        title="Decide what other kinds of personal services I receive"
        class="mb-4"
        ref="personalServicesSection"
      >
        <v-expand-transition>
          <div
            v-if="local.functions.personalServices"
            class="mx-4"
          >
            <h4 class="my-4">
              Would you like to place any limits on this function?
            </h4>
            <div class="toggle-yes-no">
              <item-select
                :value="limitFlags.personalServices"
                @select="toggleFlag('personalServices', true)"
                title="Yes"
              />
              <item-select
                :value="!limitFlags.personalServices"
                @select="toggleFlag('personalServices', false)"
                title="No"
              />
            </div>
            <v-textarea
              :rules="[maxLengthRule]"
              v-if="limitFlags.personalServices"
              v-bind="textAreaFieldGrow"
              v-model="local.functions.personalServicesDetails"
              :error-messages="
                showErrors.personalServices && errorMessages.personalServices
                  ? errorMessages.personalServices
                  : null
              "
            />
          </div>
        </v-expand-transition>
      </item-select>

      <item-select
        :value="local.functions.medicalDental"
        @select="local.functions.medicalDental = !local.functions.medicalDental"
        title="Consent to the carrying out of medical or dental treatment on me"
        class="mb-4"
        ref="medicalDentalSection"
      >
        <div class="pl-14 pr-4 pb-4">
          (in accordance with Part 5 of the
          <a
            href="https://legislation.nsw.gov.au/view/html/inforce/current/act-1987-257#pt.5"
            target="_blank"
          >
            <i>Guardianship Act 1987</i></a
          >)
        </div>
        <v-expand-transition>
          <div
            v-if="local.functions.medicalDental"
            class="mx-4"
          >
            <h4 class="my-4">
              Would you like to place any limits on this function?
            </h4>
            <div class="toggle-yes-no">
              <item-select
                :value="limitFlags.medicalDental"
                @select="toggleFlag('medicalDental', true)"
                title="Yes"
              />
              <item-select
                :value="!limitFlags.medicalDental"
                @select="toggleFlag('medicalDental', false)"
                title="No"
              />
            </div>
            <v-textarea
              :rules="[maxLengthRule]"
              v-if="limitFlags.medicalDental"
              v-bind="textAreaFieldGrow"
              v-model="local.functions.medicalDentalDetails"
              :error-messages="
                showErrors.medicalDental && errorMessages.medicalDental
                  ? errorMessages.medicalDental
                  : null
              "
            />
          </div>
        </v-expand-transition>
      </item-select>

      <item-select
        :value="limitFlags.other"
        @select="toggleFlag('other', !limitFlags.other)"
        title="Other"
        class="mb-4"
        ref="otherSection"
      >
        <v-expand-transition>
          <div
            v-if="limitFlags.other"
            class="mx-4"
          >
            <div class="my-4">
              An example of other functions may include the ability to decide
              with whom you should have contact and who may visit you.
            </div>
            <v-textarea
              :rules="[maxLengthRule]"
              v-bind="textAreaFieldGrow"
              v-model="local.functions.other"
              :error-messages="
                showErrors.other && errorMessages.other
                  ? errorMessages.other
                  : null
              "
            />
          </div>
        </v-expand-transition>
      </item-select>
      <v-input
        :error-messages="
          showErrors.functions && errorMessages.functions
            ? errorMessages.functions
            : null
        "
      />

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import forms from '../../../mixins/forms.js'
import { isEmptyTrimmed, trimIfExists } from '../../../utils'

const storeName = 'poa_nsw_eg'
const documentId = 'poaNswEg'

export default {
  name: 'FunctionsAndLimitations',
  mixins: [forms(storeName, documentId)],
  data: () => ({
    showErrors: {
      livingSituation: false,
      healthCare: false,
      personalServices: false,
      medicalDental: false,
      other: false,
      functions: false,
    },
    limitFlags: {
      livingSituation: false,
      healthCare: false,
      personalServices: false,
      medicalDental: false,
      other: false,
    },
  }),
  mounted() {
    this.limitFlags = {
      livingSituation: this.local.functions.livingSituationDetails
        ? true
        : false,
      healthCare: this.local.functions.healthCareDetails ? true : false,
      personalServices: this.local.functions.personalServicesDetails
        ? true
        : false,
      medicalDental: this.local.functions.medicalDentalDetails ? true : false,
      other: this.local.functions.other ? true : false,
    }
  },
  methods: {
    toggleFlag(flag, value) {
      this.setAllShowErrors(false)
      this.limitFlags[flag] = value
    },
    onSubmitFail() {
      if (this.errorMessages.livingSituation)
        this.$refs.livingSituationSection.$el.scrollIntoView()
      if (this.errorMessages.healthCare)
        this.$refs.healthCareSection.$el.scrollIntoView()
      if (this.errorMessages.personalServices)
        this.$refs.personalServicesSection.$el.scrollIntoView()
      if (this.errorMessages.medicalDental)
        this.$refs.medicalDentalSection.$el.scrollIntoView()
      if (this.errorMessages.other) this.$refs.otherSection.$el.scrollIntoView()
    },
    async save() {
      this.$store.dispatch('account/save_poa', {
        id: documentId,
        data: {
          functions: {
            ...this.localFormatted.functions,
            livingSituationDetails: trimIfExists(
              this.localFormatted.functions.livingSituationDetails
            ),
            healthCareDetails: trimIfExists(
              this.localFormatted.functions.healthCareDetails
            ),
            personalServicesDetails: trimIfExists(
              this.localFormatted.functions.personalServicesDetails
            ),
            medicalDentalDetails: trimIfExists(
              this.localFormatted.functions.medicalDentalDetails
            ),
            other: trimIfExists(this.localFormatted.functions.other),
          },
        },
      })
    },
  },
  computed: {
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (
        this.limitFlags.livingSituation &&
        isEmptyTrimmed(this.local.functions.livingSituationDetails)
      )
        msgs.livingSituation = 'Please enter details.'
      if (
        this.limitFlags.healthCare &&
        isEmptyTrimmed(this.local.functions.healthCareDetails)
      )
        msgs.healthCare = 'Please enter details.'
      if (
        this.limitFlags.personalServices &&
        isEmptyTrimmed(this.local.functions.personalServicesDetails)
      )
        msgs.personalServices = 'Please enter details.'
      if (
        this.limitFlags.medicalDental &&
        isEmptyTrimmed(this.local.functions.medicalDentalDetails)
      )
        msgs.medicalDental = 'Please enter details.'
      if (this.limitFlags.other && isEmptyTrimmed(this.local.functions.other))
        msgs.other = 'Please enter details.'
      if (
        !this.local.functions.livingSituation &&
        !this.local.functions.healthCare &&
        !this.local.functions.personalServices &&
        !this.local.functions.medicalDental &&
        !this.local.functions.other
      )
        msgs.functions = 'Please select at least one function.'
      return msgs
    },
    guardianSuffix() {
      return this.local.guardians.length === 1 ? '' : 's'
    },
    localFormatted() {
      return {
        functions: {
          livingSituation: this.local.functions.livingSituation,
          livingSituationDetails:
            this.local.functions.livingSituation &&
            this.limitFlags.livingSituation
              ? this.local.functions.livingSituationDetails
              : null,
          healthCare: this.local.functions.healthCare,
          healthCareDetails:
            this.local.functions.healthCare && this.limitFlags.healthCare
              ? this.local.functions.healthCareDetails
              : null,
          personalServices: this.local.functions.personalServices,
          personalServicesDetails:
            this.local.functions.personalServices &&
            this.limitFlags.personalServices
              ? this.local.functions.personalServicesDetails
              : null,
          medicalDental: this.local.functions.medicalDental,
          medicalDentalDetails:
            this.local.functions.medicalDental && this.limitFlags.medicalDental
              ? this.local.functions.medicalDentalDetails
              : null,
          other:
            this.limitFlags.other && this.local.functions.other
              ? this.local.functions.other
              : null,
        },
      }
    },
  },
}
</script>
