<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">Commencement</h2>

      <div class="mb-4">
        <item-select
          v-for="item in commencementOptions"
          :key="item.key"
          :value="item.key === localFormatted.commencement"
          @select="selectCommencementOption(item.key)"
          :title="item.label"
          :text="item.text"
          class="mb-2"
        />
        <v-input
          :error-messages="
            showErrors.commencement && errorMessages.commencement
              ? errorMessages.commencement
              : null
          "
        />
      </div>

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import forms from '../../../mixins/forms.js'
import { commencementOptions } from '../../epaLists'

const storeName = 'poa_sa_epa'
const documentId = 'poaSaEpa'

export default {
  name: 'Commencement',
  mixins: [forms(storeName, documentId)],
  data: () => ({
    showErrors: {
      commencement: false,
    },
    commencementOptions: commencementOptions,
  }),
  methods: {
    selectCommencementOption(key) {
      this.setAllShowErrors(false)
      this.local.commencement = key
    },
  },
  computed: {
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (!this.local.commencement)
        msgs.commencement = 'Please select an option'
      return msgs
    },
    localFormatted() {
      return {
        commencement: this.local.commencement,
      }
    },
  },
}
</script>
