<template>
  <v-dialog
    v-model="isOpen"
    :max-width="500"
    persistent
  >
    <v-card>
      <v-list-item
        dense
        class="bg-sand"
      >
        <v-list-item-content>
          <h3 class="download-doc-title">
            Select which document(s) to generate
          </h3>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            icon
            @click="$emit('close')"
            ><v-icon color="black">mdi-close</v-icon></v-btn
          >
        </v-list-item-action>
      </v-list-item>

      <div
        class="text-center pa-4"
        v-if="downloadFailed"
      >
        <v-alert
          outlined
          color="red"
          icon="mdi-alert-circle"
          style="margin: 0"
        >
          <div class="font-weight-medium">
            Generation of documents failed. Please try again. If the issue
            persists, please contact support.
          </div>
        </v-alert>
      </div>

      <v-list-item
        class="pa-4"
        v-else
      >
        <v-list-item-content style="padding: 0">
          <item-select
            v-for="(doc, index) in local"
            :key="doc.documentDir"
            :value="doc.toDownload"
            @select="toggleDoc(doc)"
            :title="doc.name"
            :text="
              doc.downloaded
                ? 'Document has been downloaded.'
                : doc.downloadFailed
                  ? 'Generating document failed, please try again.'
                  : doc.generating
                    ? 'Generating...'
                    : doc.token
                      ? 'Ready to download.'
                      : doc.isValid
                        ? 'Complete.'
                        : 'Incomplete, required information is missing.'
            "
            :disabled="!doc.isValid"
            :loading="doc.generating"
            :index="index"
            :class="[
              { failed: doc.downloadFailed },
              { invalid: !doc.isValid },
              { 'mb-2': index + 1 < local.length },
            ]"
            class="select-documents"
          >
            <div
              class="px-4 py-3"
              v-if="doc.token"
            >
              <v-btn
                @click="download(doc)"
                large
                block
                :loading="false"
              >
                <v-icon
                  left
                  color="primary"
                  >mdi-download</v-icon
                >
                Download
              </v-btn>
            </div>
          </item-select>
        </v-list-item-content>
      </v-list-item>

      <div class="divider" />
      <v-btn
        v-if="!allDocumentsGenerated"
        text
        tile
        x-large
        block
        download
        @click="generateDocuments"
      >
        Generate document
        <span v-bind:style="{ opacity: multipleSelected ? '1' : '0' }">s</span>
      </v-btn>
      <div class="divider" />
      <v-btn
        text
        tile
        block
        class="bg-grey-10"
        @click="$emit('close')"
        >Close</v-btn
      >
    </v-card>
  </v-dialog>
</template>

<script>
import { cloneDeep } from 'lodash'
import { mapGetters } from 'vuex'

import { functions } from '../../../plugins/firebase'
import ItemSelect from '../../components/ui/ItemSelect'

export default {
  name: 'DownloadDoc',
  components: {
    ItemSelect,
  },
  props: {
    isOpen: {
      default: true,
    },
  },
  data: () => ({
    local: null,
    downloadToken: null,
    downloadFailed: false,
  }),
  mounted() {
    this.local = cloneDeep(this.docsToDownload)
  },
  methods: {
    toggleDoc(doc) {
      if (doc.isValid && !doc.token) doc.toDownload = !doc.toDownload
    },
    async generateDocuments() {
      this.local.forEach(async (doc) => {
        doc.downloadFailed = false
        if (!doc.isValid || !doc.toDownload) return
        doc.generating = true
        try {
          const resp = await functions.httpsCallable('documentsGeneratePoa')({
            uid: this.$store.getters['account/auth_user'].uid,
            docDirs: [doc.documentDir],
            docNames: [doc.name],
          })
          if (resp.data?.token) {
            doc.token = resp.data.token
            doc.toDownload = false
          }
        } catch (error) {
          doc.downloadFailed = true
        }
        doc.generating = false
      })
    },
    download(doc) {
      const newTab = window.open(this.getDownloadUrl(doc.token), '_new')
      newTab.blur()
      doc.downloaded = true
      window.focus()
    },
    getDownloadUrl(token) {
      return `${
        process.env.VUE_APP_DOCUMENT_DOWNLOAD_URL
      }?token=${encodeURIComponent(token)}`
    },
  },
  computed: {
    ...mapGetters('account', ['docsToDownload']),
    multipleSelected() {
      return this.local?.filter((doc) => doc.toDownload)?.length > 1
    },
    allDocumentsGenerated() {
      return this.local?.every((doc) => doc.token)
    },
  },
}
</script>

<style lang="scss">
.select-documents {
  .text-sm {
    color: #4caf50;
    font-weight: 600;
    opacity: 1;
  }
}
.select-documents.invalid {
  .text-sm {
    color: rgb(198, 80, 69);
  }
}
.select-documents.failed {
  .text-sm {
    color: var(--orange);
  }
}
</style>
