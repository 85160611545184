const appointmentOptions = [
  {
    label: 'Jointly',
    key: 'jointly',
  },
  {
    label: 'Jointly and severally',
    key: 'jointly-and-severally',
  },
]

const commencementOptions = [
  {
    label: 'Notwithstanding legal incapacity',
    text: `I declare this power to become effective upon the execution of this deed and remain effective notwithstanding that I may suffer any subsequent legal incapacity.`,
    key: 'notwithstanding',
  },
  {
    label: 'In the event of my legal incapacity',
    text: `I declare this power to become effective only in the event of my suffering any subsequent legal incapacity.`,
    key: 'incapacity',
  },
]

export { appointmentOptions, commencementOptions }
