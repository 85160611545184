<template>
  <div
    v-if="local"
    ref="top"
  >
    <transition
      name="component-fade"
      mode="out-in"
    >
      <v-window
        touchless
        v-model="window"
      >
        <v-window-item
          v-for="(attorney, indexOfAttorney) in local.attorneys"
          :key="indexOfAttorney"
          :value="indexOfAttorney"
        >
          <h2 class="mb-4">
            Appointment of alternative attorney(s)
            <span class="optional">(optional)</span>
          </h2>
          <notification-box
            type="info"
            class="mb-4"
            :flat="true"
          >
            <div class="py-1">
              <div class="font-weight-medium mb-2">Instructions</div>
              <ul>
                <li>
                  This form allows you to optionally appoint up to 3 alternative
                  attorneys for
                  {{ entities[attorney.entity].name }}.
                </li>
              </ul>
            </div>
          </notification-box>

          <h3 class="mb-4">
            Would you like to appoint alternative attorney(s) for
            {{ entities[attorney.entity].name }}?
          </h3>

          <div class="toggle-yes-no">
            <item-select
              :value="showAppointAlternatives[indexOfAttorney]"
              @select="setShowAppointAlternatives(indexOfAttorney, true)"
              title="Yes"
            />
            <item-select
              :value="!showAppointAlternatives[indexOfAttorney]"
              @select="setShowAppointAlternatives(indexOfAttorney, false)"
              title="No"
            />
          </div>

          <transition
            name="slide"
            mode="out-in"
          >
            <div v-if="showAppointAlternatives[indexOfAttorney]">
              <h3 class="mb-4">
                Alternative attorneys for
                {{ entities[attorney.entity].name }}:
              </h3>
              <div
                v-if="!attorney.alternatives.length"
                class="text-no-result"
                key="empty"
              >
                - No alternative attorneys selected -
              </div>
              <div
                key="list"
                v-else
              >
                <transition-group
                  name="slide"
                  mode="out-in"
                >
                  <item-person
                    v-for="(
                      alternative, indexOfAlternative
                    ) in attorney.alternatives"
                    :key="100 * indexOfAttorney + indexOfAlternative"
                    class="mb-2"
                    nameSize="text-md"
                    :id="alternative"
                    :editable="editable"
                    type="person"
                    @remove="
                      removeAlternative(indexOfAttorney, indexOfAlternative)
                    "
                    ref="attorneyRef"
                  >
                    <template v-slot:details="{ entity }">
                      <div
                        v-if="entity.type === 'person'"
                        class="text-sm text-cutoff opacity-70 py-1"
                      >
                        <span
                          v-if="!entity.address"
                          class="font-italic"
                          >- Address Required -</span
                        >
                        <span v-else>
                          {{ entity.address }}
                        </span>
                      </div>
                    </template>
                  </item-person>
                </transition-group>
              </div>
              <div
                class="mb-4"
                v-if="attorney.alternatives.length < 3"
              >
                <div class="text-label my-4">Add an alternative attorney:</div>
                <btn-add-large
                  :key="'btn' + indexOfAttorney"
                  class="mb-2"
                  :label="`Add${
                    attorney.alternatives.length ? ` another` : ``
                  } alternative attorney`"
                  @click="
                    ;(attorneyToAddAlternativeTo = indexOfAttorney),
                      (openAlternativeDrawerPerson = true)
                  "
                />
              </div>
              <transition
                name="slide"
                mode="out-in"
              >
                <div
                  v-if="attorney.alternatives.length"
                  class="mt-6"
                >
                  <h4 class="mb-2">
                    When can
                    {{
                      attorney.alternatives.length > 1
                        ? 'these alternative attorneys'
                        : 'this alternative attorney'
                    }}
                    act?
                    <span class="optional">(optional)</span>
                  </h4>
                  <p>
                    You can specify below when your alternative attorney(s) can
                    act. If you do not specify, an alternative attorney can only
                    take the place of the attorney if:
                  </p>
                  <ul class="mb-4">
                    <li>the attorney is unable or unwilling to act; or</li>
                    <li>
                      the appointment of your attorney is revoked (cancelled)
                      because they are no longer eligible to be your attorney
                      (for example, the attorney becomes your care worker or
                      health provider).
                    </li>
                  </ul>
                  <p
                    class="definition-link"
                    @click="showExample = true"
                  >
                    See example
                  </p>
                  <v-textarea
                    :rules="[maxLengthRule]"
                    v-bind="textAreaFieldGrow"
                    v-model="attorney.whenAlternativesAct"
                  />
                </div>
              </transition>
              <transition
                name="slide"
                mode="out-in"
              >
                <div v-if="attorney.alternatives.length > 1">
                  <h4 class="mb-2">
                    How must these alternative attorneys act?
                  </h4>
                  <item-select
                    v-for="item in appointmentOptions.slice(0, -1)"
                    titleSize="text-md"
                    :key="item.key"
                    :value="item.key === attorney.alternativeAppointment"
                    @select="selectAppointmentOption(indexOfAttorney, item.key)"
                    :title="item.label"
                    :text="item.text"
                    class="mb-2"
                  />
                  <item-select
                    v-if="attorney.alternatives.length > 2"
                    titleSize="text-md"
                    :value="
                      appointmentOptions.slice(-1)[0].key ===
                      attorney.alternativeAppointment
                    "
                    @select="
                      selectAppointmentOption(
                        indexOfAttorney,
                        appointmentOptions.slice(-1)[0].key
                      )
                    "
                    :title="appointmentOptions.slice(-1)[0].label"
                    :text="appointmentOptions.slice(-1)[0].text"
                  />
                  <v-input
                    :error-messages="
                      showErrors.alternativeAppointment &&
                      errorMessages.alternativeAppointment
                        ? errorMessages.alternativeAppointment
                        : null
                    "
                  />
                </div>
              </transition>
            </div>
          </transition>

          <div class="d-flex align-center">
            <v-btn
              v-bind="backButton"
              @click="back"
            >
              <v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}
            </v-btn>
            <v-spacer />
            <v-btn
              v-bind="buttonType"
              depressed
              @click="submit(indexOfAttorney)"
              >{{ submitLabel }}</v-btn
            >
          </div>
          <drawer
            :open.sync="openAlternativeDrawerPerson"
            title="Add alternative attorney"
          >
            <drawer-person-select
              v-if="openAlternativeDrawerPerson"
              @select="addAlternativeAttorney"
              :exclude="excludePeople"
              :editable="{ ...editable, name: false }"
              :mandatory="['address']"
            />
          </drawer>
        </v-window-item>
      </v-window>
    </transition>
    <drawer
      :open.sync="showExample"
      title="Examples"
      class="faq-drawer"
    >
      <div class="pa-4">
        <div class="text-lg font-weight-bold mb-4">
          When can this alternative attorney act?
        </div>
        <p>
          For example, you may have appointed your son-in-law as your attorney,
          but would not want your son-in-law to act as your attorney in the
          event that he is divorced from your daughter. In such a case, you
          would want your alternative attorney to act in place of your (former)
          son-in-law.
        </p>
        <p>Sample wording for the above example might be as follows:</p>
        <p class="example-text">
          I specify that my alternative attorney for John Smith can act in place
          of John Smith in the event that John Smith is divorced from my
          daughter, Jane Smith.
        </p>
      </div>
    </drawer>
  </div>
</template>

<script>
import entitiesForms from '../../../mixins/entitiesForms.js'
import forms from '../../../mixins/forms.js'
import { appointmentOptions } from '../../epaLists'

const storeName = 'poa_vic_epa'
const documentId = 'poaVicEpa'

export default {
  name: 'AppointAlternatives',
  mixins: [
    forms(storeName, documentId),
    entitiesForms('attorneys', 'Maximum of 4 attorneys.'),
  ],
  data: () => ({
    showErrors: {
      alternativeAppointment: false,
    },
    editable: { address: true },
    openAlternativeDrawerPerson: false,
    attorneyToAddAlternativeTo: null,
    window: 0,
    appointmentOptions: appointmentOptions,
    showAppointAlternatives: [false, false, false, false],
    showExample: false,
  }),
  mounted() {
    this.showAppointAlternatives = this.local.attorneys.map(
      (a) => a.alternatives.length > 0
    )
  },
  methods: {
    async submit(index) {
      this.processing = true
      if (this.isValid) {
        if (this.isChanged) {
          await this.save()
        }
        setTimeout(() => {
          this.processing = false
          this.next(index)
        }, 500)
        return
      }

      this.onSubmitFail()
      this.showErrorsSwitch()
      this.processing = false
    },
    setShowAppointAlternatives(index, value) {
      this.setAllShowErrors(false)
      this.$set(this.showAppointAlternatives, index, value)
    },
    addAlternativeAttorney(alternativeId) {
      this.setAllShowErrors(false)
      this.local.attorneys[this.attorneyToAddAlternativeTo].alternatives.push(
        alternativeId
      )
      this.openAlternativeDrawerPerson = false
    },
    removeAlternative(indexOfAttorney, indexOfAlternative) {
      this.setAllShowErrors(false)
      this.local.attorneys[indexOfAttorney].alternatives.splice(
        indexOfAlternative,
        1
      )
      if (
        this.local.attorneys[indexOfAttorney].alternativeAppointment ===
          'majority' &&
        this.local.attorneys[indexOfAttorney].alternatives.length < 3
      )
        this.local.attorneys[indexOfAttorney].alternativeAppointment = null
    },
    selectAppointmentOption(indexOfAttorney, option) {
      this.setAllShowErrors(false)
      this.local.attorneys[indexOfAttorney].alternativeAppointment = option
    },
    back() {
      this.$emit('top')
      if (this.window > 0) this.window--
      else this.$emit('back')
    },
    next(index) {
      if (index + 1 === this.local.attorneys.length) {
        this.$emit('close')
        return
      }
      this.$emit('top')
      this.window++
    },
  },
  computed: {
    attorneys() {
      return this[storeName].attorneys.map((o) => ({
        ...this.entities_people.find((e) => e.id === o.entity),
        ...o,
        alternatives: o.alternatives.map((a) => ({
          ...this.entities_people.find((e) => e.id === a),
          id: a,
        })),
      }))
    },
    entities() {
      const entities = {}
      this.entities_people.forEach((o) => {
        entities[o.id] = o
      })
      return entities
    },
    excludePeople() {
      return [
        ...this.childEntities,
        ...this.localFormatted.attorneys.map((a) => a.entity),
        ...this.localFormatted.attorneys.map((a) => a.alternatives).flat(),
      ]
    },
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (
        this.localFormatted.attorneys[this.window].alternatives?.length > 1 &&
        !this.localFormatted.attorneys[this.window].alternativeAppointment
      )
        msgs.alternativeAppointment = 'Required.'
      return msgs
    },
    localFormatted() {
      return {
        attorneys:
          this.local.attorneys.map((a, i) => ({
            ...a,
            alternatives: this.showAppointAlternatives[i] ? a.alternatives : [],
            whenAlternativesAct: this.showAppointAlternatives[i]
              ? a.whenAlternativesAct
              : null,
            alternativeAppointment: this.showAppointAlternatives[i]
              ? a.alternativeAppointment
              : null,
          })) || [],
      }
    },
  },
}
</script>
