const appointmentOptions = [
  {
    label: 'Jointly',
    text: `This means the Enduring Guardians must agree and make all decisions together.`,
    key: 'jointly',
  },
  {
    label: 'Severally',
    text: `This means the Enduring Guardians can make decisions independently of each other.`,
    key: 'severally',
  },
  {
    label: 'Jointly and Severally',
    text: `This means the Enduring Guardians can either make decisions together or separately.`,
    key: 'jointly-severally',
  },
]

const appointmentTerminationOptions = [
  {
    label: 'Terminate appointment',
    text: `I want the appointment to be terminated if one of my Enduring Guardians dies, resigns or loses capacity.`,
    key: 'terminated',
  },
  {
    label: 'Do not terminate appointment',
    text: `I do not want the appointment to be terminated if one of my Enduring Guardians dies, resigns or loses capacity. Rather, I want the remaining Enduring Guardian(s) to continue in their role.`,
    key: 'not-terminated',
  },
]

export { appointmentOptions, appointmentTerminationOptions }
