<template>
  <div>
    <div>
      <section-item
        @edit="open('decision-makers')"
        class="mb-1"
      >
        <div class="text-label mb-2">
          Medical Treatment Decision Maker{{ decisionMakersSuffix }}
        </div>
        <ol
          v-if="decisionMakers.length"
          class="mt-2"
        >
          <li
            v-for="item in decisionMakers"
            :key="item.entity"
            class="mb-1"
          >
            {{ item.name }}
            <chip-required
              v-if="!allRequiredFieldsComplete(item)"
              text="Information required"
              @click="openEditPerson(item.entity)"
              :class="{ shake: shake }"
            />
          </li>
        </ol>
        <div v-else>
          <chip-required
            :class="{ shake: shake }"
            @click="open('decision-makers')"
          />
          <div class="text-no-result text-left">
            Click 'Edit' on the right to add at least 1 medical treatment
            decision maker.
          </div>
        </div>
      </section-item>

      <section-item
        @edit="open('conditions')"
        class="mb-1"
        ><div class="text-label optional">(Optional)</div>
        <div class="text-label">Limitations and conditions</div>
        <div
          v-if="!local.conditions"
          class="text-no-result text-left"
        >
          Click 'Edit' on the right to optionally add limitations and/or
          conditions.
        </div>
        <div
          v-else
          class="clamp-3"
        >
          {{ local.conditions }}
        </div>
      </section-item>

      <dialog-full
        :open.sync="dialogOpen"
        title="Medical Treatment Decision Makers"
        :path="gaUrl"
      >
        <component
          v-if="openForm"
          :is="`dialog-${openForm}`"
          @close="close"
          @next="next"
        />
      </dialog-full>
    </div>
    <drawer
      title="Edit Person"
      :open.sync="openEdit"
    >
      <div class="pa-4">
        <slot name="form">
          <form-person
            v-if="openEdit"
            :id="editId"
            @close="openEdit = false"
            @next="openEdit = false"
            :editable="editable"
          />
        </slot>
      </div>
    </drawer>
  </div>
</template>

<script>
import { vic_mtdm_required } from '../../../../config/poaRequiredFields'
import poa from '../mixins/poa.js'
import DialogConditions from './forms/mtdm/Conditions.vue'
import DialogDecisionMakers from './forms/mtdm/DecisionMakers.vue'

const storeName = 'poa_vic_mtdm'
const documentDir = 'vicMtdm'

export default {
  name: 'PoaMedicalTreatmentDecisionMakers',
  mixins: [poa(storeName)],
  components: {
    DialogDecisionMakers,
    DialogConditions,
  },
  data: () => ({
    editable: { address: true, phone: true, dob: true },
    required: vic_mtdm_required,
    documentDir: documentDir,
  }),
  methods: {},
  computed: {
    decisionMakers() {
      return this[storeName].decisionMakers.map((d) => ({
        entity: d,
        ...this.entities_people.find((e) => e.id === d),
      }))
    },
    decisionMakersSuffix() {
      return this.decisionMakers.length === 1 ? '' : 's'
    },
  },
}
</script>
