<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">Directions <span class="optional">(optional)</span></h2>
      <p>
        You may give your Enduring Guardian{{ guardianSuffix }} directions about
        how you want them to carry out their functions.
      </p>
      <p>
        For example, you can direct them to consult other family members before
        making a decision or give details about the kind of place you would like
        to live if you lost capacity.
      </p>
      <p>
        An Enduring Guardian must exercise their functions according to any
        directions you give them. You can only give lawful directions. You
        cannot give directions to your Enduring Guardian{{ guardianSuffix }}
        to do something which is against the law.
      </p>

      <h3 class="mb-4">
        Would you like to provide directions to your Enduring Guardian{{
          guardianSuffix
        }}?
      </h3>

      <div class="toggle-yes-no">
        <item-select
          :value="showTextbox"
          @select="showTextbox = true"
          title="Yes"
        />
        <item-select
          :value="!showTextbox"
          @select="showTextbox = false"
          title="No"
        />
      </div>

      <v-textarea
        :rules="[maxLengthRule]"
        v-if="showTextbox"
        v-bind="textAreaFieldGrow"
        v-model="local.directions"
        :error-messages="
          showErrors.directions && errorMessages.directions
            ? errorMessages.directions
            : null
        "
      />

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import forms from '../../../mixins/forms.js'
import { isEmptyTrimmed, trimIfExists } from '../../../utils'

const storeName = 'poa_nsw_eg'
const documentId = 'poaNswEg'

export default {
  name: 'Directions',
  mixins: [forms(storeName, documentId)],
  data: () => ({
    showErrors: {
      directions: false,
    },
    showTextbox: false,
  }),
  mounted() {
    this.showTextbox = !!this.local.directions
  },
  methods: {
    async save() {
      this.$store.dispatch('account/save_poa', {
        id: documentId,
        data: {
          directions: trimIfExists(this.localFormatted.directions),
        },
      })
    },
  },
  computed: {
    guardianSuffix() {
      return this.local.guardians.length === 1 ? '' : 's'
    },
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (this.showTextbox && isEmptyTrimmed(this.localFormatted.directions))
        msgs.directions = 'Please enter your directions.'
      return msgs
    },
    localFormatted() {
      return {
        directions: this.showTextbox ? this.local.directions : null,
      }
    },
  },
}
</script>
