<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">
        Substitute Enduring Guardian <span class="optional">(optional)</span>
      </h2>
      <p>
        A substitute Enduring Guardian is someone you appoint to be your
        Enduring Guardian if your Enduring
        {{
          this.local.guardians.length > 1
            ? 'Guardians die, resign or become incapacitated.'
            : 'Guardian dies, resigns or becomes incapacitated.'
        }}
      </p>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Instructions</div>
          <p>
            Click the button below to optionally add a substitute Enduring
            Guardian.
          </p>
        </div>
      </notification-box>

      <transition
        name="component-fade"
        mode="out-in"
      >
        <div v-if="local.substitute">
          <h3 class="mb-4">Your substitute Enduring Guardian</h3>
          <item-person
            class="mb-4"
            :id="local.substitute"
            :editable="editable"
            type="person"
            @remove="local.substitute = null"
          >
            <template v-slot:details="{ entity }">
              <div
                v-if="entity.type === 'person'"
                class="text-sm text-cutoff opacity-70 py-1"
              >
                <span
                  v-if="!entity.address"
                  class="font-italic"
                  >- Address Required -</span
                >
                <span v-else>
                  {{ entity.address }}
                </span>
              </div>
            </template>
          </item-person>
        </div>
      </transition>

      <div class="mb-2">
        <btn-add-large
          :icon="local.substitute ? 'mdi-swap-horizontal' : 'mdi-plus'"
          :label="buttonLabel"
          class="mb-2"
          @click="openDrawerPerson = true"
          @clickDisabled="disabledMessage()"
        />
      </div>
      <v-input
        :error-messages="
          showErrors.substitute && errorMessages.substitute
            ? errorMessages.substitute
            : null
        "
      />

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
    <drawer
      :open.sync="openDrawerPerson"
      title="Add substitute Enduring Guardian"
    >
      <drawer-person-select
        v-if="openDrawerPerson"
        @select="addSubstituteGuardian"
        :exclude="excludePeople"
        :editable="{ ...editable, name: false }"
        :mandatory="['address']"
      />
    </drawer>
  </div>
</template>

<script>
import entitiesForms from '../../../mixins/entitiesForms.js'
import forms from '../../../mixins/forms.js'

const storeName = 'poa_nsw_eg'
const documentId = 'poaNswEg'

export default {
  name: 'SubstituteGuardians',
  mixins: [forms(storeName, documentId), entitiesForms('guardians', '')],
  data: () => ({
    showErrors: {
      substitute: false,
    },
    editable: { address: true, occupation: true, phone: true },
  }),
  methods: {
    addSubstituteGuardian(id) {
      this.local.substitute = id
      this.openDrawerPerson = false
    },
  },
  computed: {
    excludePeople() {
      return [
        ...this.childEntities,
        ...this.local.guardians,
        this.localFormatted.substitute,
      ]
    },
    buttonLabel() {
      return `${
        this.local?.substitute ? 'Change' : 'Optionally add a'
      } substitute Enduring Guardian`
    },
    localFormatted() {
      return {
        substitute: this.local.substitute || null,
      }
    },
  },
}
</script>
