<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">Unacceptable to you</h2>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Instructions</div>
          <p>
            It is recommended that you discuss this section with your decision
            maker(s), family or doctor as it is important that anything you
            write should be readily understood by the people who are supporting
            and treating you.
          </p>
        </div>
      </notification-box>

      <h3 class="mb-4">
        If nearing death, what is unacceptable to you? What do you NOT want?
      </h3>
      <p>
        For example, not wanting particular family or people to visit or see me,
        being alone and feeling helpless etc.
      </p>

      <v-textarea
        :rules="[maxLengthRule]"
        v-bind="textAreaFieldGrow"
        v-model="local.advanceCareStatement.unacceptable"
        :error-messages="
          showErrors.unacceptable && errorMessages.unacceptable
            ? errorMessages.unacceptable
            : null
        "
      />

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import forms from '../../../mixins/forms.js'
import { trimIfExists } from '../../../utils'

const storeName = 'poa_nt_app'
const documentId = 'poaNtApp'

export default {
  name: 'UnacceptableToYou',
  mixins: [forms(storeName, documentId)],
  data: () => ({
    showErrors: {
      unacceptable: false,
    },
  }),
  methods: {
    async save() {
      this.$store.dispatch('account/save_poa', {
        id: documentId,
        data: {
          advanceCareStatement: {
            ...this.localFormatted.advanceCareStatement,
            unacceptable: trimIfExists(
              this.localFormatted.advanceCareStatement.unacceptable
            ),
          },
        },
      })
    },
  },
  computed: {
    localFormatted() {
      return {
        advanceCareStatement: {
          ...this.local.advanceCareStatement,
          unacceptable:
            this.local && this.local.advanceCareStatement.unacceptable
              ? this.local.advanceCareStatement.unacceptable
              : null,
        },
      }
    },
  },
}
</script>
