export default {
  data: function () {
    return {
      isIntersecting: false,
      window: 'default',
    }
  },
  methods: {
    onIntersect(entries) {
      // More information about these options
      // is located here: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
      this.isIntersecting = entries[0].intersectionRatio >= 0.5
    },
    changeWindow(window) {
      if (!this.isIntersecting) {
        this.$vuetify.goTo('#anchor')

        setTimeout(() => {
          this.window = window
        }, 200)
      } else {
        this.window = window
      }
    },
  },
}
