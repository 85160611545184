<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">Personal attorneys</h2>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Instructions</div>
          <p>
            You can appoint one or more attorneys to make decisions about
            personal (including health) matters for you. Personal matters relate
            to personal or lifestyle decisions. This includes decisions about
            support services, where and with whom you live, health care and
            legal matters that do not relate to your financial or property
            matters.
          </p>
          <p>Add up to 4 people as your attorneys.</p>
          <p>Your attorney(s) must:</p>
          <ul class="mb-4">
            <li>
              have capacity to make decisions for the matter they are being
              appointed for
            </li>
            <li>be 18 years or older</li>
            <li>
              not be a service provider for a residential service if you are a
              resident there
            </li>
            <li>
              not be your paid carer in the previous three years or your health
              provider.
            </li>
          </ul>
          <p>
            Note: a paid carer is someone who is paid a fee or wage to care for
            a person but not someone receiving a carer’s pension or benefit.
          </p>
        </div>
      </notification-box>

      <h3 class="mb-4">Your attorneys:</h3>

      <transition
        name="component-fade"
        mode="out-in"
      >
        <div
          v-if="!localFormatted.attorneys.length"
          class="text-no-result"
          key="empty"
        >
          - Add at least one attorney -
        </div>
        <div
          key="list"
          v-else
        >
          <transition-group
            name="component-fade"
            mode="out-in"
          >
            <item-person
              v-for="(item, index) in localFormatted.attorneys"
              :key="item + index"
              class="mb-2"
              :id="item"
              :editable="editable"
              type="person"
              @remove="removeAttorney(index)"
            >
              <template v-slot:details="{ entity }">
                <div
                  v-if="entity.type === 'person'"
                  class="text-sm text-cutoff opacity-70 py-1"
                >
                  <span
                    v-if="!allRequiredFieldsComplete(entity)"
                    class="font-italic"
                    >- Information required -</span
                  >
                  <span v-else>
                    {{ entity.address }}
                  </span>
                </div>
              </template>
            </item-person>
          </transition-group>
        </div>
      </transition>

      <div class="text-label my-4">Add an attorney:</div>
      <div class="mb-2">
        <btn-add-large
          label="Add person"
          class="mb-2"
          @click="openDrawerPerson = true"
          @clickDisabled="disabledMessage()"
          :disabled="isMaximumSelected"
        />
      </div>
      <v-input
        :error-messages="
          showErrors.attorneys && errorMessages.attorneys
            ? errorMessages.attorneys
            : null
        "
      />

      <v-expand-transition>
        <div
          v-if="this.local.attorneys.length > 1"
          class="mb-4"
        >
          <h2 class="mb-4">
            How must your attorneys make decisions for personal (including
            health) matters?
          </h2>
          <item-select
            v-for="item in appointmentOptions.slice(0, -1)"
            :key="item.key"
            :value="item.key === localFormatted.appointment"
            @select="selectAppointmentOption(item.key)"
            :title="item.label"
            :text="item.text"
            class="mb-2"
          />
          <item-select
            :value="
              localFormatted.appointment ===
              appointmentOptions[appointmentOptions.length - 1].key
            "
            @select="
              selectAppointmentOption(
                appointmentOptions[appointmentOptions.length - 1].key
              )
            "
            :title="appointmentOptions[appointmentOptions.length - 1].label"
            :text="appointmentOptions[appointmentOptions.length - 1].text"
          >
            <v-expand-transition>
              <div
                v-if="
                  localFormatted.appointment ===
                  appointmentOptions[appointmentOptions.length - 1].key
                "
                class="mx-4"
              >
                <h3 class="my-4">Please specify:</h3>
                <v-textarea
                  :rules="[maxLengthRule]"
                  v-bind="textAreaFieldGrow"
                  v-model="local.appointmentOther"
                  :error-messages="
                    showErrors.appointmentOther &&
                    errorMessages.appointmentOther
                      ? errorMessages.appointmentOther
                      : null
                  "
                />
              </div>
            </v-expand-transition>
          </item-select>
          <v-input
            :error-messages="
              showErrors.appointment && errorMessages.appointment
                ? errorMessages.appointment
                : null
            "
          />
        </div>
      </v-expand-transition>

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
    <drawer
      :open.sync="openDrawerPerson"
      title="Add attorney"
    >
      <drawer-person-select
        v-if="openDrawerPerson"
        @select="addAttorney"
        :exclude="excludePeople"
        :editable="{ ...editable, name: false }"
        :mandatory="required"
      />
    </drawer>
  </div>
</template>

<script>
import { qld_epa_required } from '../../../../../../config/poaRequiredFields'
import entitiesForms from '../../../mixins/entitiesForms.js'
import forms from '../../../mixins/forms.js'
import { isEmptyTrimmed, trimIfExists } from '../../../utils'
import { appointmentOptions } from '../../lists'

const storeName = 'poa_qld_epa'
const documentId = 'poaQldEpa'

export default {
  name: 'PersonalAttorneys',
  mixins: [
    forms(storeName, documentId),
    entitiesForms('attorneys', 'Maximum of 4 personal attorneys.'),
  ],
  data: () => ({
    showErrors: {
      attorneys: false,
      appointment: false,
      appointmentOther: false,
    },
    editable: { name: true, address: true, phone: true, email: true },
    required: qld_epa_required,
    appointmentOptions: appointmentOptions,
  }),
  methods: {
    async save() {
      this.$store.dispatch('account/save_poa', {
        id: documentId,
        data: {
          ...this.localFormatted,
          appointmentOther: trimIfExists(this.localFormatted.appointmentOther),
        },
      })
    },
    addAttorney(id) {
      this.setAllShowErrors(false)
      this.local.attorneys.push(id)
      this.openDrawerPerson = false
    },
    removeAttorney(index) {
      this.setAllShowErrors(false)
      this.$delete(this.local.attorneys, index)
    },
    selectAppointmentOption(option) {
      this.setAllShowErrors(false)
      this.local.appointment = option
    },
  },
  computed: {
    excludePeople() {
      return [...this.childEntities, ...this.localFormatted.attorneys]
    },
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (!this.localFormatted.attorneys.length)
        msgs.attorneys = 'Select at least one attorney.'
      if (this.localFormatted.attorneys.length > 4)
        msgs.attorneys = 'Maximum of 4 attorneys.'
      if (
        this.localFormatted.attorneys.length > 1 &&
        !this.localFormatted.appointment
      )
        msgs.appointment = 'Required'
      if (
        this.localFormatted.appointment === 'other' &&
        isEmptyTrimmed(this.localFormatted.appointmentOther)
      )
        msgs.appointmentOther = 'Required'
      return msgs
    },
    localFormatted() {
      let appointment = this.local?.appointment || null
      let appointmentOption = appointment
        ? this.appointmentOptions.find((o) => o.key === appointment) || null
        : null
      return {
        attorneys: this.local.attorneys || [],
        appointment:
          this.local.attorneys.length <= 1
            ? null
            : appointmentOption
              ? appointment
              : null,
        appointmentOther:
          this.local.attorneys.length > 1 &&
          appointmentOption &&
          appointmentOption.key === 'other'
            ? this.local.appointmentOther
            : null,
      }
    },
  },
}
</script>
