<template>
  <div>
    <accordion-main v-model="panel">
      <accordion-main-panel
        v-for="(item, index) in panels"
        :ref="`panel-${index}`"
        :key="index"
        :title="item.title"
        :subtitle="item.subtitle"
        :id="`panel-${item.key}`"
        class="panel-poa"
      >
        <template
          v-slot:header-append
          v-if="item.isValid"
        >
          <v-list-item-avatar
            color="success"
            size="28"
            class="mr-3"
          >
            <v-icon
              color="white"
              size="18"
              >mdi-check-bold</v-icon
            >
          </v-list-item-avatar>
        </template>
        <component
          v-show="item.component"
          :key="item.component"
          :is="`${item.component}`"
        />
      </accordion-main-panel>
      <v-overlay
        :value="poaAccessState === 'expired'"
        absolute
        class="text-center"
        color="black"
        opacity="0.6"
      >
        <v-icon
          size="48"
          class="mb-4"
          color="white"
          >mdi-lock-outline</v-icon
        >
        <p class="text-white font-weight-bold">
          Renew your account to continue to make changes to your POA.
        </p>
        <v-btn
          v-bind="smallButton"
          @click="$router.push('/checkout')"
          >Renew</v-btn
        >
      </v-overlay>
    </accordion-main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import AccordionMain from '../../components/wrapper/AccordionMain'
import AccordionMainPanel from '../../components/wrapper/AccordionMainPanel'
import About from './About'
import ActEpa from './act/Epa'
import NswEg from './nsw/Eg'
import NswEpa from './nsw/Epa'
import PrescribedWitnessing from './nsw/PrescribedWitnessing'
import RevocationForms from './nsw/RevocationForms'
import NtApp from './nt/App'
import QldEpa from './qld/Epa'
import SaAcd from './sa/Acd'
import SaEpa from './sa/Epa'
import TasEg from './tas/Eg'
import TasEpa from './tas/Epa'
import VicEpa from './vic/Epa'
import VicMtdm from './vic/Mtdm'
import WaEpa from './wa/Epa'
import WaEpg from './wa/Epg'

export default {
  name: 'PagePoaGetStarted',
  components: {
    About,
    AccordionMain,
    AccordionMainPanel,
    ActEpa,
    NswEg,
    NswEpa,
    NtApp,
    QldEpa,
    SaAcd,
    SaEpa,
    TasEg,
    TasEpa,
    VicEpa,
    VicMtdm,
    WaEpa,
    WaEpg,
    PrescribedWitnessing,
    RevocationForms,
  },
  data: () => ({
    panel: null,
    customPanel: [
      {
        state: 'vic',
        panels: [
          {
            title: 'Enduring Power of Attorney',
            subtitle: 'For personal and/or financial matters.',
            component: 'VicEpa',
          },
          {
            title: 'Medical Treatment Decision Maker',
            subtitle: 'For medical matters.',
            component: 'VicMtdm',
          },
        ],
      },
      {
        state: 'tas',
        panels: [
          {
            title: 'Enduring Power of Attorney',
            subtitle: 'For financial matters.',
            component: 'TasEpa',
          },
          {
            title: 'Enduring Guardianship',
            subtitle: 'For medical and personal matters.',
            component: 'TasEg',
          },
        ],
      },
      {
        state: 'wa',
        panels: [
          {
            title: 'Enduring Power of Attorney',
            subtitle: 'For financial matters.',
            component: 'WaEpa',
          },
          {
            title: 'Enduring Power of Guardianship',
            subtitle: 'For medical and personal matters.',
            component: 'WaEpg',
          },
        ],
      },
      {
        state: 'nsw',
        panels: [
          {
            title: 'Enduring Power of Attorney',
            subtitle: 'For financial matters.',
            component: 'NswEpa',
          },
          {
            title: 'Enduring Guardianship',
            subtitle: 'For medical and lifestyle matters.',
            component: 'NswEg',
          },
        ],
      },
      {
        state: 'qld',
        panels: [
          {
            title: 'Enduring Power of Attorney',
            subtitle: 'For medical, personal and financial matters.',
            component: 'QldEpa',
          },
        ],
      },
      {
        state: 'sa',
        panels: [
          {
            title: 'Enduring Power of Attorney',
            subtitle: 'For legal and financial matters.',
            component: 'SaEpa',
          },
          {
            title: 'Advanced Care Directive',
            subtitle: 'For medical matters.',
            component: 'SaAcd',
          },
        ],
      },
      {
        state: 'wa',
        panels: [
          {
            title: 'Enduring Power of Attorney',
            subtitle: 'For legal and financial matters.',
            component: 'WaEpa',
          },
          {
            title: 'Enduring Power of Guardianship',
            subtitle: 'For medical and personal matters.',
            component: 'WaEpg',
          },
        ],
      },
      {
        state: 'nt',
        panels: [
          {
            title: 'Advanced Personal Plan',
            subtitle: 'For medical and personal matters.',
            component: 'NtApp',
          },
        ],
      },

      {
        state: 'act',
        panels: [
          {
            title: 'Enduring Power of Attorney',
            subtitle: 'For personal, financial and medical matters.',
            component: 'ActEpa',
          },
        ],
      },
    ],
  }),
  props: {
    poaAccessState: {
      default: 'unpurchased',
    },
  },
  computed: {
    ...mapGetters('account', [
      'user_address',
      'validityOfDocsForState',
      'aboutYouComplete',
    ]),
    panels() {
      const panels = [
        {
          title: 'About You',
          key: 'about',
          component: 'about',
          subtitle: this.aboutYouComplete
            ? 'You have completed this section.'
            : 'Enter your details here to get started. Once this is complete you will able to create the Power of Attorney documents for your state.',
          isValid: this.aboutYouComplete,
        },
      ]
      if (this.aboutYouComplete)
        panels.push(
          ...this.customPanel
            .filter((panel) => panel.state == this.user_address.value.state)[0]
            .panels.map((panel) => ({
              ...panel,
              isValid: this.validityOfDocsForState.find(
                (doc) => doc.documentName == `poa${panel.component}`
              ).isValid,
            }))
        )
      return this.user_address.value.state === 'nsw'
        ? panels.concat([
            {
              title: 'Witnessing instructions',
              subtitle: 'Learn more about who can witness your documents.',
              component: 'PrescribedWitnessing',
            },
            {
              title: 'Revocation forms',
              subtitle: 'Access forms to revoke previous documents.',
              component: 'RevocationForms',
            },
          ])
        : panels
    },
  },
}
</script>
