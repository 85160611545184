<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">
        Limitations and conditions <span class="optional">(optional)</span>
      </h2>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Instructions</div>
          <p>
            You may place limitations and/or conditions on the way your medical
            treatment decision maker{{ decisionMakerSuffix }} make decisions for
            you.
          </p>
        </div>
      </notification-box>

      <h3 class="mb-4">
        Would you like to provide any limitations and/or conditions for your
        medical treatment decision maker{{ decisionMakerSuffix }}?
        <span class="optional">(optional)</span>
      </h3>

      <div class="toggle-yes-no">
        <item-select
          :value="showTextbox"
          @select="showTextbox = true"
          title="Yes"
        />
        <item-select
          :value="!showTextbox"
          @select="showTextbox = false"
          title="No"
        />
      </div>
      <p
        class="definition-link"
        @click="exampleOpen = true"
        v-if="showTextbox"
      >
        See example
      </p>
      <v-textarea
        :rules="[maxLengthRule]"
        v-if="showTextbox"
        v-bind="textAreaFieldGrow"
        v-model="local.conditions"
        :error-messages="
          showErrors.conditions && errorMessages.conditions
            ? errorMessages.conditions
            : null
        "
      />

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
    <drawer
      :open.sync="exampleOpen"
      title="Example"
      class="faq-drawer"
    >
      <div class="pa-4">
        <div class="text-lg font-weight-bold mb-4">
          Limitations and conditions
        </div>
        <p>For example:</p>
        <ul>
          <li>
            <p>
              You may wish to specify that the appointed medical treatment
              decision maker consult with particular people (noting that a
              medical treatment decision maker is required by the Act to consult
              with any person they reasonably believe you would want them to
              consult with in the circumstances).
            </p>
            <p>Sample wording for the above example might be as follows:</p>
            <p class="example-text">
              I want my Medical Treatment Decision Maker(s) to consult with my
              second cousin, Jane Watson, regarding any medical treatment
              decisions that need to be made.
            </p>
          </li>
          <li>
            <p>
              You may wish to specify that the person is only empowered to make
              certain types of decisions.
            </p>
            <p>Sample wording for the above example might be as follows:</p>
            <p class="example-text">
              My Medical Treatment Decision Maker(s) is only empowered to make
              decisions regarding medical treatments that do not involve an
              invasive surgical procedure.
            </p>
            <div
              style="
                padding: 6px 10px;
                border: 1px solid #555;
                background-color: #f1f1f1;
                margin-bottom: 16px;
              "
            >
              <p>
                <b>Note</b>: If the appointed medical treatment decision maker
                is not empowered to make a particular decision (and a medical
                treatment decision needs to be made) whoever else would be your
                medical treatment decision maker would be empowered by the
                legislation to make the decision. In such a case, the medical
                treatment decision maker is the first person in the list below
                who is reasonably available, and willing and able to make the
                decision:
              </p>
              <ol class="mb-4">
                <li>
                  A guardian appointed by VCAT to make decisions about medical
                  treatment.
                </li>
                <li>
                  The first person in the list below who is in a close and
                  continuing relationship with the patient:
                  <ol style="list-style-type: lower-alpha">
                    <li>The patient's spouse or domestic partner.</li>
                    <li>
                      The patient's primary carer (not a paid service provider).
                    </li>
                    <li>An adult child of the patient.</li>
                    <li>A parent of the patient.</li>
                    <li>An adult sibling.</li>
                  </ol>
                </li>
              </ol>
              <p class="mb-0">
                If there are two or more relatives who are first on the above
                list, it is the eldest person.
              </p>
            </div>
          </li>
          <li>
            <p>
              You may wish to set an expiry date for an appointed medical
              treatment decision maker to be in the role. However, if the
              appointed medical treatment decision maker’s appointment has
              expired (and a medical treatment decision needs to be made)
              whoever else would be your medical treatment decision maker would
              be empowered by the legislation to make the decision as explained
              in the note above.
            </p>
            <p>Sample wording for the above example might be as follows:</p>
            <p class="example-text">
              I specify that I want my appointed Medical Treatment Decision
              Maker(s) to act in that capacity until no later than 15 March
              2028.
            </p>
          </li>
          <li>
            <p>
              You may wish to specify that the medical treatment decision maker
              is to seek advice from the Victorian Civil and Administrative
              Tribunal (VCAT) in relation to specific medical treatment
              decisions.
            </p>
            <p>Sample wording for the above example might be as follows:</p>
            <p class="example-text">
              My Medical Treatment Decision Maker(s) must seek advice from the
              Victorian Civil and Administrative Tribunal in relation to any
              medical treatment decisions that relate to invasive surgical
              procedures.
            </p>
          </li>
        </ul>
      </div>
    </drawer>
  </div>
</template>

<script>
import forms from '../../../mixins/forms.js'
import { isEmptyTrimmed, trimIfExists } from '../../../utils'

const storeName = 'poa_vic_mtdm'
const documentId = 'poaVicMtdm'

export default {
  name: 'Conditions',
  mixins: [forms(storeName, documentId)],
  data: () => ({
    showErrors: {
      conditions: false,
    },
    showTextbox: false,
    exampleOpen: false,
  }),
  mounted() {
    this.showTextbox = !!this.local.conditions
  },
  methods: {
    async save() {
      this.$store.dispatch('account/save_poa', {
        id: documentId,
        data: {
          conditions: trimIfExists(this.localFormatted.conditions),
        },
      })
    },
  },
  computed: {
    decisionMakerSuffix() {
      return this.local.decisionMakers.length === 1 ? '' : 's'
    },
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (this.showTextbox && isEmptyTrimmed(this.localFormatted.conditions))
        msgs.conditions = 'Please enter your limitations and/or conditions.'
      return msgs
    },
    localFormatted() {
      return {
        conditions: this.showTextbox ? this.local.conditions : null,
      }
    },
  },
}
</script>
