<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">Appointment of attorneys</h2>
      <p>
        The person you appoint to make decisions on your behalf is called your
        ‘attorney’.
      </p>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Instructions</div>
          <p>
            An attorney can be a family member or friend or someone else that
            you trust or an
            <span
              class="definition-link"
              @click="openExample('occupant')"
              >occupant of a position</span
            >.
          </p>
          <p>An attorney <b>must</b> be 18 years of age or older.</p>
          <p>
            An attorney <b>cannot</b> be an
            <span
              class="definition-link"
              @click="openExample('insolvent')"
            >
              insolvent under administration </span
            >.
          </p>
          <p>You <b>cannot</b> appoint:</p>
          <ul class="mb-4">
            <li>
              your
              <span
                class="definition-link"
                @click="openExample('care')"
                >care worker</span
              >,
            </li>
            <li>
              your
              <span
                class="definition-link"
                @click="openExample('health')"
              >
                health provider</span
              >, or
            </li>
            <li>
              your
              <span
                class="definition-link"
                @click="openExample('accommodation')"
              >
                accommodation provider</span
              >.
            </li>
          </ul>
          <p>This form allows you to appoint up to four attorneys.</p>
          <p>
            You can also appoint a person(s) to be a back-up for one or more
            attorney(s), in case your usual attorney is ever unable or unwilling
            to act. This person is called an ‘alternative attorney’. This option
            will be available on the next screen.
          </p>
        </div>
      </notification-box>

      <h3 class="mb-4">Your attorneys:</h3>

      <transition
        name="component-fade"
        mode="out-in"
      >
        <div
          v-if="!localFormatted.attorneys.length"
          class="text-no-result"
          key="empty"
        >
          - Add at least one attorney -
        </div>
        <div
          key="list"
          v-else
        >
          <transition-group
            name="component-fade"
            mode="out-in"
          >
            <item-person
              v-for="(attorney, index) in localFormatted.attorneys"
              :key="attorney.entity + index"
              class="mb-2"
              :id="attorney.entity"
              :editable="editable"
              type="person"
              @remove="removeAttorney(index)"
              ref="attorney"
            >
              <template v-slot:details="{ entity }">
                <div
                  v-if="entity.type === 'person'"
                  class="text-sm text-cutoff opacity-70 py-1"
                >
                  <span
                    v-if="!entity.address"
                    class="font-italic"
                    >- Address Required -</span
                  >
                  <span v-else>
                    {{ entity.address }}
                  </span>
                </div>
              </template>
            </item-person>
          </transition-group>
        </div>
      </transition>

      <div class="text-label my-4">Add an attorney:</div>
      <div class="mb-2">
        <btn-add-large
          label="Add person"
          class="mb-6"
          @click="openDrawerPerson = true"
          @clickDisabled="disabledMessage()"
          :disabled="isMaximumSelected"
        />
      </div>
      <v-input
        :error-messages="
          showErrors.attorneys && errorMessages.attorneys
            ? errorMessages.attorneys
            : null
        "
      />

      <v-expand-transition>
        <div
          v-if="this.local.attorneys.length > 1"
          class="mb-4"
        >
          <h4 class="mb-4">
            How would you like to your attorneys to work together?
          </h4>
          <item-select
            v-for="item in appointmentOptions.slice(0, -1)"
            titleSize="text-md"
            :key="item.key"
            :value="item.key === localFormatted.attorneysAppointment"
            @select="selectAppointmentOption(item.key)"
            :title="item.label"
            :text="item.text"
            class="mb-2"
          />
          <item-select
            v-if="localFormatted.attorneys.length > 2"
            titleSize="text-md"
            :value="
              appointmentOptions.slice(-1)[0].key ===
              localFormatted.attorneysAppointment
            "
            @select="
              selectAppointmentOption(appointmentOptions.slice(-1)[0].key)
            "
            :title="appointmentOptions.slice(-1)[0].label"
            :text="appointmentOptions.slice(-1)[0].text"
          />
          <v-input
            :error-messages="
              showErrors.attorneysAppointment &&
              errorMessages.attorneysAppointment
                ? errorMessages.attorneysAppointment
                : null
            "
          />
        </div>
      </v-expand-transition>

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="$emit('back')"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
    <drawer
      :open.sync="openDrawerPerson"
      title="Add attorney"
    >
      <drawer-person-select
        v-if="openDrawerPerson"
        @select="addAttorney"
        :exclude="excludePeople"
        :editable="{ ...editable, name: false }"
        :mandatory="['address']"
      />
    </drawer>
    <drawer
      :open.sync="showDefinitions"
      title="Definitions"
      class="faq-drawer"
    >
      <div
        v-if="showOccupant"
        class="pa-4"
      >
        <div class="text-lg font-weight-bold mb-4">Occupant of a position</div>
        <p>E.g., an accountant or lawyer at a particular firm.</p>
      </div>
      <div
        v-if="showInsolvent"
        class="pa-4"
      >
        <div class="text-lg font-weight-bold mb-4">
          Insolvent under administration
        </div>
        <p>
          "<b>Insolvent under administration</b>" is defined at section 9 of the
          <i>Corporations Act 2001</i> as follows:
        </p>
        <ol style="list-style: lower-alpha">
          <li>
            a person who, under the <i>Bankruptcy Act 1966</i>, is a bankrupt in
            respect of a bankruptcy from which the person has not been
            discharged; or
          </li>
          <li>
            a person who, under the law of a foreign country, has the status of
            an undischarged bankrupt; or
          </li>
          <li>
            a person any of whose property is subject to control under:
            <ol style="list-style: lower-roman">
              <li>
                section 50 or Division 2 of Part X of the
                <i>Bankruptcy Act 1966</i>; or
              </li>
              <li>
                a corresponding provision of the law of a foreign country; or
              </li>
            </ol>
          </li>
          <li>
            a person who has executed a personal insolvency agreement under:
            <ol style="list-style: lower-roman">
              <li>Part X of the <i>Bankruptcy Act 1966</i>; or</li>
              <li>
                the corresponding provisions of the law of a foreign country;
              </li>
            </ol>
            where the terms of the agreement have not been fully complied with;
            or
          </li>
          <li>
            a person who is a party (as a debtor) to a debt agreement under:
            <ol style="list-style: lower-roman">
              <li>Part IX of the <i>Bankruptcy Act 1966</i>; or</li>
              <li>
                the corresponding provisions of the law of a foreign country.
              </li>
            </ol>
          </li>
        </ol>
      </div>
      <div
        v-if="showAccommodation"
        class="pa-4"
      >
        <div class="text-lg font-weight-bold mb-4">Accommodation provider</div>
        <p>
          According to section 3 of the <i>Powers of Attorney Act 2014</i>, "<b
            >accommodation provider</b
          >", for an individual, means a person who is, in a professional or
          administrative capacity, directly or indirectly responsible for or
          involved in the provision of accommodation to the individual.
        </p>
      </div>
      <div
        v-if="showHealthProvider"
        class="pa-4"
      >
        <div class="text-lg font-weight-bold mb-4">Health provider</div>
        <p>
          According to section 3 of the <i>Powers of Attorney Act 2014</i>, "<b
            >health provider</b
          >" means a person who provides health care in the practice of a
          profession or in the ordinary course of business.
        </p>
      </div>
      <div
        v-if="showCareWorker"
        class="pa-4"
      >
        <div class="text-lg font-weight-bold mb-4">Care worker</div>
        <p>
          According to section 3 of the <i>Powers of Attorney Act 2014</i>, "<b
            >care worker</b
          >", for an individual, means a person who performs services for the
          care of the individual and receives remuneration for those services
          from any source, but does not include—
        </p>
        <ol style="list-style: lower-alpha">
          <li>
            a person who receives a carer payment or other benefit from the
            Commonwealth or a State or a Territory of the Commonwealth for
            providing home care for the individual; or
          </li>
          <li>a person who is a health provider.</li>
        </ol>
      </div>
    </drawer>
  </div>
</template>

<script>
import entitiesForms from '../../../mixins/entitiesForms.js'
import forms from '../../../mixins/forms.js'
import { appointmentOptions } from '../../epaLists'

const storeName = 'poa_vic_epa'
const documentId = 'poaVicEpa'

export default {
  name: 'AppointAttorneys',
  mixins: [
    forms(storeName, documentId),
    entitiesForms('attorneys', 'Maximum of 4 attorneys.'),
  ],
  data: () => ({
    showErrors: {
      attorneys: false,
      attorneysAppointment: false,
    },
    editable: { address: true },
    appointmentOptions: appointmentOptions,
    showDefinitions: false,
    showOccupant: false,
    showInsolvent: false,
    showAccommodation: false,
    showHealthProvider: false,
    showCareWorker: false,
  }),
  methods: {
    addAttorney(id) {
      this.setAllShowErrors(false)
      this.local.attorneys.push({
        entity: id,
        whenAlternativesAct: null,
        alternativeAppointment: null,
        alternatives: [],
      })
      this.openDrawerPerson = false
    },
    removeAttorney(index) {
      if (this.local.attorneys.length === 3) {
        this.local.attorneysAppointment = null
      }
      this.setAllShowErrors(false)
      this.$delete(this.local.attorneys, index)
    },
    selectAppointmentOption(option) {
      this.setAllShowErrors(false)
      this.local.attorneysAppointment = option
    },
    openExample(type) {
      this.showOccupant = false
      this.showInsolvent = false
      this.showAccommodation = false
      this.showHealthProvider = false
      this.showCareWorker = false
      this.showDefinitions = true
      this.showOccupant = type === 'occupant'
      this.showInsolvent = type === 'insolvent'
      this.showAccommodation = type === 'accommodation'
      this.showHealthProvider = type === 'health'
      this.showCareWorker = type === 'care'
    },
  },
  computed: {
    excludePeople() {
      return [
        ...this.childEntities,
        ...this.localFormatted.attorneys.map((a) => a.entity),
        ...this.localFormatted.attorneys.map((a) => a.alternatives).flat(),
      ]
    },
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (!this.localFormatted.attorneys.length)
        msgs.attorneys = 'Select at least one attorney.'
      if (this.localFormatted.attorneys.length > 4)
        msgs.attorneys = 'Maximum of 4 attorneys.'
      if (
        this.localFormatted.attorneys.length > 1 &&
        !this.localFormatted.attorneysAppointment
      )
        msgs.attorneysAppointment = 'Required'
      return msgs
    },
    localFormatted() {
      let attorneysAppointment = this.local?.attorneysAppointment || null
      let appointmentOptions = attorneysAppointment
        ? this.appointmentOptions.find((o) => o.key === attorneysAppointment) ||
          null
        : null
      return {
        attorneys: this.local.attorneys || [],
        attorneysAppointment:
          this.local.attorneys.length <= 1
            ? null
            : appointmentOptions
              ? attorneysAppointment
              : null,
      }
    },
  },
}
</script>
