<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">Appointment of substitute guardians</h2>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Instructions</div>
          <p>
            A substitute guardian is a person you can appoint to act as your
            guardian in the event that your originally appointed guardian is
            ever unavailable, unsuitable or unable to act as your guardian.
          </p>
          <p>
            If you appoint a substitute guardian, you must also specify the
            circumstances under which you want your substitute guardian to act,
            and the guardian(s) you want your substitute guardian to act in
            place of.
          </p>
          <p>Add up to 4 people as your substitute guardians.</p>
        </div>
      </notification-box>

      <h3 class="mb-4">Your substitute guardians:</h3>

      <transition
        name="component-fade"
        mode="out-in"
      >
        <div
          v-if="!localFormatted.substituteGuardians.length"
          class="text-no-result"
          key="empty"
        >
          - Add at least one guardian -
        </div>
        <div
          key="list"
          v-else
        >
          <transition-group
            name="component-fade"
            mode="out-in"
          >
            <item-person
              v-for="(item, index) in localFormatted.substituteGuardians"
              :key="item.entity"
              class="mb-2"
              :id="item.entity"
              :editable="editable"
              type="person"
              @remove="removeAttorney(index)"
              ref="substituteGuardiansRef"
            >
              <template v-slot:details="{ entity }">
                <div
                  v-if="entity.type === 'person'"
                  class="text-sm text-cutoff opacity-70 py-1"
                >
                  <span
                    v-if="!entity.address"
                    class="font-italic"
                    >- Address Required -</span
                  >
                  <span v-else>
                    {{ entity.address }}
                  </span>
                </div>
              </template>
              <div class="mx-4 my-2">
                <h4 class="mb-2">In substitution of:</h4>
                <item-select
                  v-for="(guardian, inSubstitutionOfIndex) in guardians"
                  :key="guardian.id + index + inSubstitutionOfIndex"
                  :value="item.inSubstitutionOf === guardian.id"
                  @select="selectInSubstitutionOf(index, guardian.id)"
                  :title="guardian.name"
                  :class="{
                    'mb-2': inSubstitutionOfIndex + 1 < guardians.length,
                  }"
                />
                <v-input
                  class="mb-2"
                  :error-messages="
                    showErrors[`inSubstitutionOf${index}`] &&
                    errorMessages[`inSubstitutionOf${index}`]
                      ? errorMessages[`inSubstitutionOf${index}`]
                      : null
                  "
                />
              </div>
            </item-person>
          </transition-group>
        </div>
      </transition>

      <div class="text-label my-4">Add guardian:</div>
      <div class="mb-2">
        <btn-add-large
          label="Add person"
          class="mb-2"
          @click="openDrawerPerson = true"
          @clickDisabled="disabledMessage()"
          :disabled="isMaximumSelected"
        />
      </div>
      <v-input
        :error-messages="
          showErrors.substituteGuardians && errorMessages.substituteGuardians
            ? errorMessages.substituteGuardians
            : null
        "
      />

      <v-expand-transition>
        <div
          v-if="this.local.substituteGuardians.length > 0"
          class="mb-4"
        >
          <h2 class="mb-4">
            {{
              local.substituteGuardians.length > 1
                ? 'Under which circumstances are your substitute guardians to be your enduring guardians?'
                : 'Under which circumstances is your substitute guardian to be your enduring guardian?'
            }}
          </h2>
          <v-textarea
            :rules="[maxLengthRule]"
            v-bind="textAreaFieldGrow"
            v-model="local.substituteGuardiansCircumstances"
            :error-messages="
              showErrors.substituteGuardiansCircumstances &&
              errorMessages.substituteGuardiansCircumstances
                ? errorMessages.substituteGuardiansCircumstances
                : null
            "
          />
        </div>
      </v-expand-transition>

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
    <drawer
      :open.sync="openDrawerPerson"
      title="Add guardian"
    >
      <drawer-person-select
        v-if="openDrawerPerson"
        @select="addAttorney"
        :exclude="excludePeople"
        :editable="{ ...editable, name: false }"
        :mandatory="['address']"
      />
    </drawer>
  </div>
</template>

<script>
import entitiesForms from '../../../mixins/entitiesForms.js'
import forms from '../../../mixins/forms.js'
import { trimIfExists } from '../../../utils'

const storeName = 'poa_wa_epg'
const documentId = 'poaWaEpg'

export default {
  name: 'AppointGuardians',
  mixins: [
    forms(storeName, documentId),
    entitiesForms('substituteGuardians', 'Maximum of 4 substitute guardians.'),
  ],
  data: () => ({
    showErrors: {
      guardians: false,
      inSubstitutionOf0: false,
      inSubstitutionOf1: false,
      inSubstitutionOf2: false,
      inSubstitutionOf3: false,
      substituteGuardiansCircumstances: false,
    },
    openDrawerPerson: false,
    editable: { address: true },
  }),
  methods: {
    onSubmitFail() {
      for (
        let index = this.local.substituteGuardians.length;
        index >= 0;
        index--
      ) {
        this.showErrorsSwitch(true, `inSubstitutionOf${index}`)
        if (this.errorMessages[`inSubstitutionOf${index}`])
          this.$refs.substituteGuardiansRef[index].$el.scrollIntoView()
      }
    },
    async save() {
      this.$store.dispatch('account/save_poa', {
        id: documentId,
        data: {
          ...this.localFormatted,
          substituteGuardiansCircumstances: trimIfExists(
            this.localFormatted.substituteGuardiansCircumstances
          ),
        },
      })
    },
    addAttorney(id) {
      this.setAllShowErrors(false)
      this.local.substituteGuardians.push({
        entity: id,
        inSubstitutionOf: null,
      })
      this.openDrawerPerson = false
    },
    removeAttorney(index) {
      this.setAllShowErrors(false)
      this.$delete(this.local.substituteGuardians, index)
    },
    selectInSubstitutionOf(index, id) {
      this.setAllShowErrors(false)
      this.local.substituteGuardians[index].inSubstitutionOf = id
    },
  },
  computed: {
    guardians() {
      return this.entities_people.filter((o) =>
        this.poa_wa_epg.guardians.includes(o.id)
      )
    },
    excludePeople() {
      return [
        ...this.childEntities,
        ...this.localFormatted.substituteGuardians.map((item) => item.entity),
        ...this.local.guardians,
      ]
    },
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (this.localFormatted.substituteGuardians.length > 4)
        msgs.substituteGuardians = 'Maximum of 4 guardians.'
      this.localFormatted.substituteGuardians.forEach((guardian, index) => {
        if (!guardian.inSubstitutionOf)
          msgs[`inSubstitutionOf${index}`] = 'Required'
      })
      if (
        this.localFormatted.substituteGuardians.length &&
        this.localFormatted.substituteGuardiansCircumstances === ''
      )
        msgs.substituteGuardiansCircumstances = 'Required'
      return msgs
    },
    localFormatted() {
      return {
        substituteGuardians: this.local.substituteGuardians || [],
        substituteGuardiansCircumstances: this.local
          .substituteGuardiansCircumstances
          ? this.local.substituteGuardiansCircumstances
          : '',
      }
    },
  },
}
</script>
