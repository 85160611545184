<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">Consent to care</h2>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Instructions</div>
          <p>
            It is recommended that you discuss this section with your decision
            maker(s), family or doctor as it is important that anything you
            write should be readily understood by the people who are supporting
            and treating you.
          </p>
        </div>
      </notification-box>

      <h3 class="mb-4">
        Consent to palliative and comfort care so that you can feel better, even
        though it won’t cure you?
      </h3>
      <div class="toggle-yes-no">
        <item-select
          :value="local.advanceCareStatement.consentToCare"
          @select="local.advanceCareStatement.consentToCare = true"
          title="Yes"
        />
        <item-select
          :value="local.advanceCareStatement.consentToCare === false"
          @select="local.advanceCareStatement.consentToCare = false"
          title="No"
        />
      </div>

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import forms from '../../../mixins/forms.js'

const storeName = 'poa_nt_app'
const documentId = 'poaNtApp'

export default {
  name: 'ConsentToCare',
  mixins: [forms(storeName, documentId)],
  data: () => ({
    showErrors: {
      consentToCare: false,
    },
  }),
  methods: {
    async save() {
      this.$store.dispatch('account/save_poa', {
        id: documentId,
        data: this.localFormatted,
      })
    },
  },
  computed: {
    localFormatted() {
      return {
        advanceCareStatement: {
          ...this.local.advanceCareStatement,
          consentToCare:
            this.local && this.local.advanceCareStatement.consentToCare !== null
              ? this.local.advanceCareStatement.consentToCare
              : null,
        },
      }
    },
  },
}
</script>
