<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">Functions and limitations</h2>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Instructions</div>
          <p>
            Please select the functions you wish to authorise your attorney{{
              attorneySuffix
            }}
            to do on your behalf.
          </p>
        </div>
      </notification-box>

      <item-select
        :value="local.functions.property"
        @select="toggleLocalFunction('property')"
        title="Property matters (includes financial matters)"
        class="mb-4"
        ref="propertySection"
      >
        <v-expand-transition>
          <div
            v-if="local.functions.property"
            class="mx-4"
          >
            <h4 class="my-4">
              Would you like to place directions, limitations and conditions on
              this power?
            </h4>
            <div class="toggle-yes-no">
              <item-select
                :value="limitFlags.property"
                @select="setLimitFlag('property', true)"
                title="Yes"
              />
              <item-select
                :value="!limitFlags.property"
                @select="setLimitFlag('property', false)"
                title="No"
              />
            </div>
            <v-textarea
              :rules="[maxLengthRule]"
              v-if="limitFlags.property"
              v-bind="textAreaFieldGrow"
              v-model="local.functions.propertyConditions"
              :error-messages="
                showErrors.property && errorMessages.property
                  ? errorMessages.property
                  : null
              "
            />
          </div>
        </v-expand-transition>
      </item-select>

      <item-select
        :value="local.functions.personalCare"
        @select="toggleLocalFunction('personalCare')"
        title="Personal care matters"
        class="mb-4"
        ref="personalCareSection"
      >
        <v-expand-transition>
          <div
            v-if="local.functions.personalCare"
            class="mx-4"
          >
            <h4 class="my-4">
              Would you like to place directions, limitations and conditions on
              this power?
            </h4>
            <div class="toggle-yes-no">
              <item-select
                :value="limitFlags.personalCare"
                @select="setLimitFlag('personalCare', true)"
                title="Yes"
              />
              <item-select
                :value="!limitFlags.personalCare"
                @select="setLimitFlag('personalCare', false)"
                title="No"
              />
            </div>
            <v-textarea
              :rules="[maxLengthRule]"
              v-if="limitFlags.personalCare"
              v-bind="textAreaFieldGrow"
              v-model="local.functions.personalCareConditions"
              :error-messages="
                showErrors.personalCare && errorMessages.personalCare
                  ? errorMessages.personalCare
                  : null
              "
            />
          </div>
        </v-expand-transition>
      </item-select>

      <item-select
        :value="local.functions.healthCare"
        @select="toggleLocalFunction('healthCare')"
        title="Health care matters"
        class="mb-4"
        ref="healthCareSection"
      >
        <v-expand-transition>
          <div
            v-if="local.functions.healthCare"
            class="mx-4"
          >
            <h4 class="my-4">
              Would you like to place directions, limitations and conditions on
              this power?
            </h4>
            <div class="toggle-yes-no">
              <item-select
                :value="limitFlags.healthCare"
                @select="setLimitFlag('healthCare', true)"
                title="Yes"
              />
              <item-select
                :value="!limitFlags.healthCare"
                @select="setLimitFlag('healthCare', false)"
                title="No"
              />
            </div>
            <v-textarea
              :rules="[maxLengthRule]"
              v-if="limitFlags.healthCare"
              v-bind="textAreaFieldGrow"
              v-model="local.functions.healthCareConditions"
              :error-messages="
                showErrors.healthCare && errorMessages.healthCare
                  ? errorMessages.healthCare
                  : null
              "
            />
          </div>
        </v-expand-transition>
      </item-select>

      <item-select
        :value="local.functions.medicalResearch"
        @select="toggleLocalFunction('medicalResearch')"
        title="Medical research matters"
        class="mb-4"
        ref="medicalResearchSection"
      >
        <v-expand-transition>
          <div
            v-if="local.functions.medicalResearch"
            class="mx-4"
          >
            <h4 class="my-4">
              Would you like to place directions, limitations and conditions on
              this power?
            </h4>
            <div class="toggle-yes-no">
              <item-select
                :value="limitFlags.medicalResearch"
                @select="setLimitFlag('medicalResearch', true)"
                title="Yes"
              />
              <item-select
                :value="!limitFlags.medicalResearch"
                @select="setLimitFlag('medicalResearch', false)"
                title="No"
              />
            </div>
            <v-textarea
              :rules="[maxLengthRule]"
              v-if="limitFlags.medicalResearch"
              v-bind="textAreaFieldGrow"
              v-model="local.functions.medicalResearchConditions"
              :error-messages="
                showErrors.medicalResearch && errorMessages.medicalResearch
                  ? errorMessages.medicalResearch
                  : null
              "
            />
          </div>
        </v-expand-transition>
      </item-select>
      <v-input
        :error-messages="
          showErrors.functions && errorMessages.functions
            ? errorMessages.functions
            : null
        "
      />

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import forms from '../../../mixins/forms.js'
import { isEmptyTrimmed, trimIfExists } from '../../../utils'

const storeName = 'poa_act_epa'
const documentId = 'poaActEpa'

export default {
  name: 'Functions',
  mixins: [forms(storeName, documentId)],
  data: () => ({
    showErrors: {
      property: false,
      healthCare: false,
      personalCare: false,
      medicalResearch: false,
      functions: false,
    },
    limitFlags: {
      property: false,
      healthCare: false,
      personalCare: false,
      medicalResearch: false,
    },
  }),
  mounted() {
    this.limitFlags = {
      property: this.local.functions.propertyConditions ? true : false,
      healthCare: this.local.functions.healthCareConditions ? true : false,
      personalCare: this.local.functions.personalCareConditions ? true : false,
      medicalResearch: this.local.functions.medicalResearchConditions
        ? true
        : false,
    }
  },
  methods: {
    setLimitFlag(key, value) {
      this.setAllShowErrors(false)
      this.limitFlags[key] = value
    },
    toggleLocalFunction(key) {
      this.setAllShowErrors(false)
      this.local.functions[key] = !this.local.functions[key]
    },
    onSubmitFail() {
      if (this.errorMessages.property)
        this.$refs.propertySection.$el.scrollIntoView()
      if (this.errorMessages.healthCare)
        this.$refs.healthCareSection.$el.scrollIntoView()
      if (this.errorMessages.personalCare)
        this.$refs.personalCareSection.$el.scrollIntoView()
      if (this.errorMessages.medicalResearch)
        this.$refs.medicalResearchSection.$el.scrollIntoView()
    },
    async save() {
      this.$store.dispatch('account/save_poa', {
        id: documentId,
        data: {
          functions: {
            ...this.localFormatted.functions,
            propertyConditions: trimIfExists(
              this.localFormatted.functions.propertyConditions
            ),
            healthCareConditions: trimIfExists(
              this.localFormatted.functions.healthCareConditions
            ),
            personalCareConditions: trimIfExists(
              this.localFormatted.functions.personalCareConditions
            ),
            medicalResearchConditions: trimIfExists(
              this.localFormatted.functions.medicalResearchConditions
            ),
          },
        },
      })
    },
  },
  computed: {
    attorneySuffix() {
      return this.local.attorneys.length > 1 ? 's' : ''
    },
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (
        this.limitFlags.property &&
        isEmptyTrimmed(this.local.functions.propertyConditions)
      )
        msgs.property = 'Please enter details.'
      if (
        this.limitFlags.healthCare &&
        isEmptyTrimmed(this.local.functions.healthCareConditions)
      )
        msgs.healthCare = 'Please enter details.'
      if (
        this.limitFlags.personalCare &&
        isEmptyTrimmed(this.local.functions.personalCareConditions)
      )
        msgs.personalCare = 'Please enter details.'
      if (
        this.limitFlags.medicalResearch &&
        isEmptyTrimmed(this.local.functions.medicalResearchConditions)
      )
        msgs.medicalResearch = 'Please enter details.'
      if (
        !this.local.functions.property &&
        !this.local.functions.healthCare &&
        !this.local.functions.personalCare &&
        !this.local.functions.medicalResearch
      )
        msgs.functions = msgs.healthCare = 'Please select at least one option.'

      return msgs
    },
    guardianSuffix() {
      return this.local.guardians.length === 1 ? '' : 's'
    },
    localFormatted() {
      return {
        functions: {
          property: this.local.functions.property,
          propertyConditions:
            this.local.functions.property && this.limitFlags.property
              ? this.local.functions.propertyConditions
              : null,
          healthCare: this.local.functions.healthCare,
          healthCareConditions:
            this.local.functions.healthCare && this.limitFlags.healthCare
              ? this.local.functions.healthCareConditions
              : null,
          personalCare: this.local.functions.personalCare,
          personalCareConditions:
            this.local.functions.personalCare && this.limitFlags.personalCare
              ? this.local.functions.personalCareConditions
              : null,
          medicalResearch: this.local.functions.medicalResearch,
          medicalResearchConditions:
            this.local.functions.medicalResearch &&
            this.limitFlags.medicalResearch
              ? this.local.functions.medicalResearchConditions
              : null,
        },
      }
    },
  },
}
</script>
