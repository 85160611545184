<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">Authority to Someone Else</h2>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Instructions</div>
          <p>
            You may authorise your attorney{{ attorneySuffix }} to authorise
            someone else to exercise all or some of their powers as your
            attorney{{ attorneySuffix }}. Choose your option below by
            initialling in the appropriate box. Cross out and initial or delete
            any option that does not apply.
          </p>
        </div>
      </notification-box>

      <h3 class="mb-4">
        What authority do you authorise your attorney{{ attorneySuffix }} to
        authorise someone else to exercise?
      </h3>

      <div class="mb-4">
        <item-select
          v-for="(item, index) in authorityOptions.slice(0, -1)"
          :key="item.key"
          :value="item.key === localFormatted.attorneyAuthoriseOther"
          @select="selectOption(item.key)"
          :title="item.label"
          :text="item.text"
          :class="{ 'mb-2': index + 1 < authorityOptions.length }"
        />
        <item-select
          :key="otherOption.key"
          :value="otherOption.key === localFormatted.attorneyAuthoriseOther"
          @select="selectOption(otherOption.key)"
          :title="otherOption.label"
          :text="otherOption.text"
        >
          <v-expand-transition>
            <div
              v-if="otherOption.key === localFormatted.attorneyAuthoriseOther"
              class="mx-4"
            >
              <h3 class="my-4">Please specify the powers:</h3>
              <v-textarea
                :rules="[maxLengthRule]"
                v-bind="textAreaFieldGrow"
                v-model="local.attorneyAuthoriseOtherSpecified"
                :error-messages="
                  showErrors.attorneyAuthoriseOtherSpecified &&
                  errorMessages.attorneyAuthoriseOtherSpecified
                    ? errorMessages.attorneyAuthoriseOtherSpecified
                    : null
                "
              />
            </div>
          </v-expand-transition>
        </item-select>
        <v-input
          :error-messages="
            showErrors.attorneyAuthoriseOther &&
            errorMessages.attorneyAuthoriseOther
              ? errorMessages.attorneyAuthoriseOther
              : null
          "
        />
      </div>

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import forms from '../../../mixins/forms.js'
import { isEmptyTrimmed, trimIfExists } from '../../../utils'
import { getAuthorityOptions } from '../../lists'

const storeName = 'poa_act_epa'
const documentId = 'poaActEpa'

export default {
  name: 'AuthorityToSomeoneElse',
  mixins: [forms(storeName, documentId)],
  data: () => ({
    showErrors: {
      attorneyAuthoriseOther: false,
      attorneyAuthoriseOtherSpecified: false,
    },
    authorityOptions: null,
    otherOption: null,
  }),
  mounted() {
    this.authorityOptions = getAuthorityOptions(this.local.attorneys.length > 1)
    this.otherOption = this.authorityOptions[this.authorityOptions.length - 1]
  },
  methods: {
    async save() {
      this.$store.dispatch('account/save_poa', {
        id: documentId,
        data: {
          ...this.localFormatted,
          attorneyAuthoriseOtherSpecified: trimIfExists(
            this.localFormatted.attorneyAuthoriseOtherSpecified
          ),
        },
      })
    },
    selectOption(key) {
      this.setAllShowErrors(false)
      this.local.attorneyAuthoriseOther = key
    },
  },
  computed: {
    attorneySuffix() {
      return this.local.attorneys.length > 1 ? 's' : ''
    },
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (
        this.localFormatted.attorneyAuthoriseOther === 'specified' &&
        isEmptyTrimmed(this.local.attorneyAuthoriseOtherSpecified)
      )
        msgs.attorneyAuthoriseOtherSpecified = 'Required'
      if (this.localFormatted.attorneyAuthoriseOther === null)
        msgs.attorneyAuthoriseOther = 'Please select an option.'
      return msgs
    },
    localFormatted() {
      return {
        attorneyAuthoriseOther: this.local.attorneyAuthoriseOther,
        attorneyAuthoriseOtherSpecified:
          this.local.attorneyAuthoriseOther === 'specified'
            ? this.local.attorneyAuthoriseOtherSpecified
            : null,
      }
    },
  },
}
</script>
