<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">Conditions, limitations or exclusions</h2>

      <h3 class="mb-4">
        Would you like to place any conditions, limitations or exlusions on your
        donee{{ doneePossessiveSuffix }}
        power?
      </h3>

      <div class="toggle-yes-no">
        <item-select
          :value="showTextbox"
          @select="selectShowTextboxOption(true)"
          title="Yes"
        />
        <item-select
          :value="!showTextbox"
          @select="selectShowTextboxOption(false)"
          title="No"
        />
      </div>

      <v-textarea
        :rules="[maxLengthRule]"
        v-if="showTextbox"
        v-bind="textAreaFieldGrow"
        v-model="local.exclusions"
        :error-messages="
          showErrors.exclusions && errorMessages.exclusions
            ? errorMessages.exclusions
            : null
        "
      />

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import forms from '../../../mixins/forms.js'
import { isEmptyTrimmed, trimIfExists } from '../../../utils'

const storeName = 'poa_sa_epa'
const documentId = 'poaSaEpa'

export default {
  name: 'ConditionsAndLimitations',
  mixins: [forms(storeName, documentId)],
  data: () => ({
    showErrors: {
      exclusions: false,
    },
    showTextbox: false,
  }),
  mounted() {
    this.showTextbox = !!this.local.exclusions
  },
  methods: {
    selectShowTextboxOption(key) {
      this.setAllShowErrors(false)
      this.showTextbox = key
    },
    async save() {
      this.$store.dispatch('account/save_poa', {
        id: documentId,
        data: {
          exclusions: trimIfExists(this.localFormatted.exclusions),
        },
      })
    },
  },
  computed: {
    doneePossessiveSuffix() {
      return this.local.donees.length > 1 ? "s'" : "'s"
    },
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (this.showTextbox && isEmptyTrimmed(this.localFormatted.exclusions)) {
        msgs.exclusions =
          'Please enter your conditions, limitations and exlusions.'
      }
      return msgs
    },
    localFormatted() {
      return {
        exclusions: this.showTextbox ? this.local.exclusions : null,
      }
    },
  },
}
</script>
