<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">Attorneys</h2>
      <p>
        It is important that you trust the person you are appointing as attorney
        to make financial decisions on your behalf. Your attorney must be over
        18 years old and must not be bankrupt or insolvent. If your financial
        affairs are complicated, you should appoint an attorney who has the
        skills to deal with complex financial arrangements.
      </p>
      <p>
        If you appoint more than one attorney, it may be useful to appoint
        people who can work cooperatively and in your best interest.
      </p>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Instructions</div>
          <p>Add up to 4 people as your attorneys.</p>
        </div>
      </notification-box>

      <h3 class="mb-4">Your attorneys:</h3>

      <transition
        name="component-fade"
        mode="out-in"
      >
        <div
          v-if="!localFormatted.attorneys.length"
          class="text-no-result"
          key="empty"
        >
          - Add at least one attorney -
        </div>
        <div
          key="list"
          v-else
        >
          <transition-group
            name="component-fade"
            mode="out-in"
          >
            <item-person
              v-for="(item, index) in localFormatted.attorneys"
              :key="item + index"
              class="mb-2"
              :id="item"
              :editable="editable"
              type="person"
              @remove="removeAttorney(index)"
            >
              <template v-slot:details="{ entity }">
                <div
                  v-if="entity.type === 'person'"
                  class="text-sm text-cutoff opacity-70 py-1"
                >
                  <span
                    v-if="!entity.address"
                    class="font-italic"
                    >- Address Required -</span
                  >
                  <span v-else>
                    {{ entity.address }}
                  </span>
                </div>
              </template>
            </item-person>
          </transition-group>
        </div>
      </transition>

      <div class="text-label my-4">Add an attorney:</div>
      <div class="mb-2">
        <btn-add-large
          label="Add person"
          class="mb-2"
          @click="openDrawerPerson = true"
          @clickDisabled="disabledMessage()"
          :disabled="isMaximumSelected"
        />
      </div>
      <v-input
        :error-messages="
          showErrors.attorneys && errorMessages.attorneys
            ? errorMessages.attorneys
            : null
        "
      />

      <v-expand-transition>
        <div
          v-if="this.local.attorneys.length > 1"
          class="mb-4"
        >
          <h3 class="mb-4">
            How would you like to your attorneys to work together?
          </h3>
          <item-select
            v-for="(item, index) in appointmentOptions"
            :key="item.key"
            :value="item.key === localFormatted.appointment"
            @select="selectAppointmentOption(item.key)"
            :title="item.label"
            :text="item.text"
            :class="{ 'mb-2': index + 1 < appointmentOptions.length }"
          />
          <v-input
            :error-messages="
              showErrors.appointment && errorMessages.appointment
                ? errorMessages.appointment
                : null
            "
          />
        </div>
      </v-expand-transition>

      <v-expand-transition>
        <div
          v-if="
            this.local.attorneys.length > 1 && local.appointment === 'jointly'
          "
          class="mb-4"
        >
          <h3 class="mb-4">
            Would you like the appointment of all attorneys to be terminated if
            any one attorney dies, resigns or otherwise vacates office?
          </h3>
          <span
            class="definition-link"
            @click="showDefinition = true"
          >
            What does it mean to vacate office?
          </span>
          <br />
          <br />
          <item-select
            v-for="(item, index) in appointmentTerminationOptions"
            :key="item.key"
            :value="item.key === localFormatted.appointmentTermination"
            @select="selectAppointmentTerminationOption(item.key)"
            :title="item.label"
            :text="item.text"
            :class="{
              'mb-2': index + 1 < appointmentTerminationOptions.length,
            }"
          />
          <div
            class="basic-warning mt-2"
            v-if="
              local.substitutesStyle === 'per-primary' &&
              localFormatted.appointmentTermination === 'terminated'
            "
          >
            <b>Warning:</b> If you change this option to
            <i>Terminate appointment</i>, you will need to complete the
            substitutes section again. This is because you cannot assign
            substitute attorneys per primary attorney if the appointment is
            terminated on the death of one primary attorney.
          </div>
          <v-input
            :error-messages="
              showErrors.appointmentTermination &&
              errorMessages.appointmentTermination
                ? errorMessages.appointmentTermination
                : null
            "
          />
        </div>
      </v-expand-transition>

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
    <drawer
      :open.sync="openDrawerPerson"
      title="Add attorney"
    >
      <drawer-person-select
        v-if="openDrawerPerson"
        @select="addAttorney"
        :exclude="excludePeople"
        :editable="{ ...editable, name: false }"
        :mandatory="['address']"
      />
    </drawer>
    <!-- Also change in SubstituteAttorneys.vue -->
    <drawer
      :open.sync="showDefinition"
      title="Definitions"
      class="faq-drawer"
    >
      <div class="pa-4">
        <div class="text-lg font-weight-bold mb-4">Vacancy in the office</div>
        <p>Section 5 of the <i>Powers of Attorney Act 2003</i></p>
        <p>
          For the purposes of this Act, there is a vacancy in the office of an
          attorney if:
        </p>
        <ol style="list-style: lower-alpha">
          <li>the appointment of the attorney is revoked, or</li>
          <li>the attorney renounces the power, or</li>
          <li>the attorney dies, or</li>
          <li>the attorney becomes bankrupt, or</li>
          <li>
            where the attorney is a corporation, the corporation is dissolved,
            or
          </li>
          <li>
            the attorney, by reason of any physical or mental incapacity, ceases
            to have the capacity to continue to act as an attorney, or
          </li>
          <li>
            in such other circumstances as may be prescribed by the regulations
            for the purposes of this paragraph.
          </li>
        </ol>
      </div>
    </drawer>
  </div>
</template>

<script>
import entitiesForms from '../../../mixins/entitiesForms.js'
import forms from '../../../mixins/forms.js'
import {
  appointmentOptions,
  appointmentTerminationOptions,
} from '../../epaLists'

const storeName = 'poa_nsw_epa'
const documentId = 'poaNswEpa'

export default {
  name: 'AppointAttorneys',
  mixins: [
    forms(storeName, documentId),
    entitiesForms('attorneys', 'Maximum of 4 attorneys.'),
  ],
  data: () => ({
    showErrors: {
      attorneys: false,
      appointment: false,
      appointmentTermination: false,
    },
    showDefinition: false,
    editable: { address: true },
    appointmentOptions: appointmentOptions,
    appointmentTerminationOptions: appointmentTerminationOptions,
  }),
  methods: {
    addAttorney(id) {
      this.setAllShowErrors(false)
      this.local.attorneys.push(id)
      this.openDrawerPerson = false
    },
    removeAttorney(index) {
      this.setAllShowErrors(false)
      this.$delete(this.local.attorneys, index)
    },
    selectAppointmentOption(option) {
      this.setAllShowErrors(false)
      this.local.appointment = option
    },
    selectAppointmentTerminationOption(option) {
      this.setAllShowErrors(false)
      this.local.appointmentTermination = option
    },
  },
  computed: {
    excludePeople() {
      return [
        ...this.childEntities,
        ...this.localFormatted.attorneys,
        ...this.local.substitutes.map((s) => s.entity),
      ]
    },
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (!this.localFormatted.attorneys.length)
        msgs.attorneys = 'Select at least one attorney.'
      if (this.localFormatted.attorneys.length > 4)
        msgs.attorneys = 'Maximum of 4 attorneys.'
      if (
        this.localFormatted.attorneys.length > 1 &&
        !this.localFormatted.appointment
      )
        msgs.appointment = 'Required'
      if (
        this.localFormatted.attorneys.length > 1 &&
        this.localFormatted.appointment === 'jointly' &&
        !this.localFormatted.appointmentTermination
      )
        msgs.appointmentTermination = 'Required'
      return msgs
    },
    localFormatted() {
      let appointment = this.local?.appointment || null
      let appointmentOption = appointment
        ? this.appointmentOptions.find((o) => o.key === appointment) || null
        : null
      let appointmentTermination = this.local?.appointmentTermination || null
      let appointmentTerminationOption = appointmentTermination
        ? this.appointmentTerminationOptions.find(
            (o) => o.key === appointmentTermination
          ) || null
        : null
      let resetSubstitutes =
        this.local.substitutesStyle === 'per-primary' &&
        (this.local.attorneys.length < 2 ||
          this.local.appointmentTermination === 'terminated')
      return {
        attorneys: this.local.attorneys || [],

        appointment:
          this.local.attorneys.length <= 1
            ? null
            : appointmentOption
              ? appointment
              : null,

        appointmentTermination:
          this.local.attorneys.length <= 1
            ? null
            : appointment !== 'jointly'
              ? null
              : appointmentTerminationOption
                ? appointmentTermination
                : null,

        substitutesStyle: resetSubstitutes ? null : this.local.substitutesStyle,
        substitutes: resetSubstitutes ? [] : this.local.substitutes,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.basic-warning {
  color: #7c5e04;
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  border-radius: 6px;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
}
</style>
