<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">Conditions upon appointment</h2>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Instructions</div>
          <p>
            If you want to give specific directions to your guardian{{
              guardianSuffix
            }}, write your own decisions about your medical care or personal
            decisions here. These are called conditions.
          </p>
        </div>
      </notification-box>

      <h3 class="mb-4">
        Would you like to provide conditions for your Enduring Guardian{{
          guardianSuffix
        }}.
      </h3>

      <div class="toggle-yes-no">
        <item-select
          :value="showTextbox"
          @select="setShowTextbox(true)"
          title="Yes"
        />
        <item-select
          :value="!showTextbox"
          @select="setShowTextbox(false)"
          title="No"
        />
      </div>

      <v-textarea
        :rules="[maxLengthRule]"
        v-if="showTextbox"
        v-bind="textAreaFieldGrow"
        v-model="local.conditions"
        :error-messages="
          showErrors.conditions && errorMessages.conditions
            ? errorMessages.conditions
            : null
        "
      />

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import forms from '../../../mixins/forms.js'
import { isEmptyTrimmed, trimIfExists } from '../../../utils'

const storeName = 'poa_tas_eg'
const documentId = 'poaTasEg'

export default {
  name: 'Directions',
  mixins: [forms(storeName, documentId)],
  data: () => ({
    showErrors: {
      conditions: false,
    },
    showTextbox: false,
  }),
  mounted() {
    this.showTextbox = !!this.local.conditions
  },
  methods: {
    setShowTextbox(value) {
      this.setAllShowErrors(false)
      this.showTextbox = value
    },
    async save() {
      this.$store.dispatch('account/save_poa', {
        id: documentId,
        data: {
          conditions: trimIfExists(this.localFormatted.conditions),
        },
      })
    },
  },
  computed: {
    guardianSuffix() {
      return this.local.guardians.length === 1 ? '' : 's'
    },
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (this.showTextbox && isEmptyTrimmed(this.localFormatted.conditions))
        msgs.conditions = 'Please enter your conditions.'
      return msgs
    },
    localFormatted() {
      return {
        conditions: this.showTextbox ? this.local.conditions : null,
      }
    },
  },
}
</script>
