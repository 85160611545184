<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">What to notify</h2>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Instructions</div>
          <p>
            Please enter what information your attorney(s) must notify you about
            when exercising a power for personal (including health) matters.
          </p>
        </div>
      </notification-box>

      <div class="mb-4">
        <item-select
          :value="localFormatted.personalNotify.what.writtenNotice"
          @select="toggleWrittenNotice()"
          title="Written notice of intention to begin exercising power"
          text="My attorney(s) for personal (including health) matters must provide written notice that they intend to begin exercising power for a personal (including health) matter under this enduring power of attorney before exercising the power for the first time."
          class="mb-2"
        />
        <item-select
          :value="showTextbox"
          @select="toggleShowTextbox"
          title="Other"
          text="Select this option to provide details about the information that must be provided, including how often your attorney(s) must provide the information."
          class="mb-2"
        >
          <v-expand-transition>
            <div
              v-if="showTextbox"
              class="mx-4"
            >
              <h3 class="my-4">
                Please specify the information to be provided:
              </h3>
              <v-textarea
                :rules="[maxLengthRule]"
                v-if="showTextbox"
                v-bind="textAreaFieldGrow"
                v-model="local.personalNotify.what.otherInformation"
                :error-messages="
                  showErrors.other && errorMessages.other
                    ? errorMessages.other
                    : null
                "
              />
            </div>
          </v-expand-transition>
        </item-select>
      </div>

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import forms from '../../../mixins/forms.js'
import { isEmptyTrimmed, trimIfExists } from '../../../utils'

const storeName = 'poa_qld_epa'
const documentId = 'poaQldEpa'

export default {
  name: 'PersonalNotifyWhat',
  mixins: [forms(storeName, documentId)],
  data: () => ({
    showErrors: {
      other: false,
    },
    showTextbox: false,
  }),
  mounted() {
    this.showTextbox = this.local.personalNotify.what.otherInformation
      ? true
      : false
  },
  methods: {
    toggleShowTextbox() {
      this.setAllShowErrors(false)
      this.showTextbox = !this.showTextbox
    },
    toggleWrittenNotice() {
      this.setAllShowErrors(false)
      this.local.personalNotify.what.writtenNotice =
        !this.local.personalNotify.what.writtenNotice
    },
    async save() {
      this.$store.dispatch('account/save_poa', {
        id: documentId,
        data: {
          personalNotify: {
            ...this.localFormatted.personalNotify,
            what: {
              writtenNotice:
                this.localFormatted.personalNotify.what.writtenNotice,
              otherInformation: trimIfExists(
                this.localFormatted.personalNotify.what.otherInformation
              ),
            },
          },
        },
      })
    },
  },
  computed: {
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (
        this.showTextbox &&
        isEmptyTrimmed(this.localFormatted.personalNotify.what.otherInformation)
      )
        msgs.other =
          'Please specify the details about the information to be provided.'

      return msgs
    },
    localFormatted() {
      return {
        personalNotify: {
          ...this.local.personalNotify,
          what: {
            writtenNotice: this.local.personalNotify.what.writtenNotice
              ? true
              : false,
            otherInformation: this.showTextbox
              ? this.local.personalNotify.what.otherInformation
              : null,
          },
        },
      }
    },
  },
}
</script>
