<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">What to notify</h2>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Instructions</div>
          <p>
            Please enter what information your attorney(s) must notify you about
            when exercising a power for financial matters.
          </p>
        </div>
      </notification-box>

      <div class="mb-4">
        <item-select
          :value="localFormatted.financialNotify.what.writtenNotice"
          @select="
            local.financialNotify.what.writtenNotice =
              !local.financialNotify.what.writtenNotice
          "
          title="Written notice"
          text="My attorney(s) for financial matters must provide written notice that they intend to begin exercising power for a financial matter under this enduring power of attorney before exercising the power for the first time."
          class="mb-2"
        />
        <item-select
          :value="localFormatted.financialNotify.what.allRecords"
          @select="
            local.financialNotify.what.allRecords =
              !local.financialNotify.what.allRecords
          "
          title="All financial records and accounts"
          text="My attorney(s) for financial matters must provide all financial records and accounts."
          class="mb-2"
        />
        <item-select
          :value="showAboveAmountTextbox"
          @select="toggleShowAboveAmountTextbox"
          title="Other"
          text="My attorney(s) for financial matters must provide records relating to transactions above a nominated amount:"
          class="mb-2"
        >
          <v-expand-transition>
            <div
              v-if="showAboveAmountTextbox"
              class="mx-4"
            >
              <h3 class="my-4">Please specify the amount:</h3>
              <v-text-field
                v-bind="textField"
                v-model="local.financialNotify.what.aboveAmount"
                :error-messages="
                  showErrors.aboveAmount && errorMessages.aboveAmount
                    ? errorMessages.aboveAmount
                    : null
                "
              />
            </div>
          </v-expand-transition>
        </item-select>
        <item-select
          :value="localFormatted.financialNotify.what.assets"
          @select="
            local.financialNotify.what.assets =
              !local.financialNotify.what.assets
          "
          title="Records and accounts for all assets"
          text="My attorney(s) for financial matters must provide records and accounts for all assets including property, investments and vehicles."
          class="mb-2"
        />
        <item-select
          :value="localFormatted.financialNotify.what.summaries"
          @select="
            local.financialNotify.what.summaries =
              !local.financialNotify.what.summaries
          "
          title="Summaries of income, expenditure and assets"
          text="My attorney(s) for financial matters must provide summaries of income, expenditure and assets."
          class="mb-2"
        />
        <item-select
          :value="localFormatted.financialNotify.what.plansAndAdvice"
          @select="
            local.financialNotify.what.plansAndAdvice =
              !local.financialNotify.what.plansAndAdvice
          "
          title="Financial management plans and advice"
          text="My attorney(s) for financial matters must provide copies of financial management plans and financial advice obtained."
          class="mb-2"
        />
        <item-select
          :value="showOtherInformationTextbox"
          @select="toggleShowOtherInformationTextbox"
          title="Other"
          class="mb-2"
        >
          <v-expand-transition>
            <div
              v-if="showOtherInformationTextbox"
              class="mx-4"
            >
              <h3 class="my-4">
                Please specify the information to be provided:
              </h3>
              <v-textarea
                :rules="[maxLengthRule]"
                v-if="showOtherInformationTextbox"
                v-bind="textAreaFieldGrow"
                v-model="local.financialNotify.what.otherInformation"
                :error-messages="
                  showErrors.otherInformation && errorMessages.otherInformation
                    ? errorMessages.otherInformation
                    : null
                "
              />
            </div>
          </v-expand-transition>
        </item-select>
      </div>

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import forms from '../../../mixins/forms.js'
import {
  isEmptyTrimmed,
  isNumericString,
  stripNumericString,
  trimIfExists,
} from '../../../utils'

const storeName = 'poa_qld_epa'
const documentId = 'poaQldEpa'

export default {
  name: 'FinancialNotifyWhat',
  mixins: [forms(storeName, documentId)],
  data: () => ({
    showErrors: {
      aboveAmount: false,
      otherInformation: false,
    },
    showAboveAmountTextbox: false,
    showOtherInformationTextbox: false,
  }),
  mounted() {
    this.showAboveAmountTextbox = this.local.financialNotify.what.aboveAmount
      ? true
      : false
    this.showOtherInformationTextbox = this.local.financialNotify.what
      .otherInformation
      ? true
      : false
  },
  methods: {
    toggleShowAboveAmountTextbox() {
      this.setAllShowErrors(false)
      this.showAboveAmountTextbox = !this.showAboveAmountTextbox
    },
    toggleShowOtherInformationTextbox() {
      this.setAllShowErrors(false)
      this.showOtherInformationTextbox = !this.showOtherInformationTextbox
    },
    async save() {
      this.$store.dispatch('account/save_poa', {
        id: documentId,
        data: {
          financialNotify: {
            ...this.localFormatted.financialNotify,
            what: {
              ...this.localFormatted.financialNotify.what,
              otherInformation: trimIfExists(
                this.localFormatted.financialNotify.what.otherInformation
              ),
            },
          },
        },
      })
    },
  },
  computed: {
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (
        this.showAboveAmountTextbox &&
        (isEmptyTrimmed(this.localFormatted.financialNotify.what.aboveAmount) ||
          !isNumericString(
            this.localFormatted.financialNotify.what.aboveAmount
          ))
      )
        msgs.aboveAmount = 'Please specify a whole numeric value.'
      if (
        this.showOtherInformationTextbox &&
        isEmptyTrimmed(
          this.localFormatted.financialNotify.what.otherInformation
        )
      )
        msgs.otherInformation =
          'Please specify the details about the information to be provided.'

      return msgs
    },
    localFormatted() {
      return {
        financialNotify: {
          ...this.local.financialNotify,
          what: {
            writtenNotice: this.local.financialNotify.what.writtenNotice
              ? true
              : false,
            allRecords: this.local.financialNotify.what.allRecords
              ? true
              : false,
            aboveAmount:
              this.showAboveAmountTextbox &&
              this.local.financialNotify.what.aboveAmount
                ? stripNumericString(
                    this.local.financialNotify.what.aboveAmount
                  )
                : null,
            assets: this.local.financialNotify.what.assets ? true : false,
            summaries: this.local.financialNotify.what.summaries ? true : false,
            plansAndAdvice: this.local.financialNotify.what.plansAndAdvice
              ? true
              : false,
            otherInformation: this.showOtherInformationTextbox
              ? this.local.financialNotify.what.otherInformation
              : null,
          },
        },
      }
    },
  },
}
</script>
