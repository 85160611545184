const appointmentOptions = [
  {
    label: 'Jointly',
    text: `Your guardians must all act together.`,
    key: 'jointly',
  },
  {
    label: 'Jointly and severally',
    text: `Your guardians may act individually or can act with the other guardians if they choose.`,
    key: 'jointly-severally',
  },
]

const appointmentTerminationOptions = [
  {
    label: 'Do not terminate appointment',
    text: `I want the surviving enduring guardians to act.`,
    key: 'not-terminated',
  },
  {
    label: 'Terminate appointment',
    text: `I do not want the surviving enduring guardians to act.`,
    key: 'terminated',
  },
]

const predefinedFunctions = [
  {
    text: `Decide where I am to live, whether permanently or temporarily.`,
    value: 'livingSituation',
  },
  {
    text: `Decide with whom I am to live.`,
    value: 'livingWith',
  },
  {
    text: `Consent, or refuse consent, on my behalf to any medical, surgical or dental treatment or other health care (including palliative care and life sustaining measures such as assisted ventilation and cardiopulmonary resuscitation).`,
    value: 'medicalTreatment',
  },
  {
    text: `Decide what education and training I am to receive.`,
    value: 'educationTraining',
  },
  {
    text: `Decide with whom I am to associate.`,
    value: 'associating',
  },
  {
    text: `Commence, defend, conduct or settle on my behalf any legal proceedings except proceedings relating to my property or estate.`,
    value: 'legalProceedings',
  },
  {
    text: `Advocate for, and make decisions about, which support services I should have access to.`,
    value: 'supportServices',
  },
  {
    text: `Seek and receive information on my behalf from any person, body or organisation.`,
    value: 'seekingInformation',
  },
]

export {
  appointmentOptions,
  appointmentTerminationOptions,
  predefinedFunctions,
}
