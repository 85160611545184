<template>
  <div>
    <div>
      <section-item
        @edit="open('views-wishes-preferences')"
        class="mb-1"
      >
        <div class="text-label">Views, wishes and preferences</div>
        <div
          v-if="!local.wishes"
          class="text-no-result text-left"
        >
          You have not added any views, wishes or preferences. Click 'Edit' on
          the right to add some.
        </div>
        <div
          v-else
          class="clamp-3"
        >
          {{ local.wishes }}
        </div>
      </section-item>

      <section-item
        @edit="open('attorneys')"
        class="mb-1"
      >
        <div class="text-label mb-2">Attorneys</div>
        <ul
          v-if="attorneys.length"
          class="mt-2"
        >
          <li
            v-for="item in attorneys"
            :key="item.id"
            class="mb-1"
          >
            {{ item.name }}
            <chip-required
              v-if="!allRequiredFieldsComplete(item)"
              text="Information required"
              @click="openEditPerson(item.id)"
              :class="{ shake: shake }"
            />
          </li>
        </ul>
        <div v-else>
          <chip-required
            :class="{ shake: shake }"
            @click="open('attorneys')"
          />
          <div class="text-no-result text-left">
            Click 'Edit' on the right to add at least 1 attorney.
          </div>
        </div>
      </section-item>

      <section-item
        @edit="open('matters')"
        class="mb-1"
      >
        <div class="text-label">Matters</div>
        <div v-if="local.personalMatters || local.financialMatters">
          <ul>
            <li v-if="local.personalMatters">
              Personal (including health) matters
            </li>
            <li v-if="local.financialMatters">Financial matters</li>
          </ul>
        </div>
        <div v-else>
          <chip-required
            :class="{ shake: shake }"
            @click="open('matters')"
          />
          <div class="text-no-result text-left">
            Click 'Edit' on the right to specify which matters you are
            appointing your attorneys to deal with.
          </div>
        </div>
      </section-item>

      <section-item
        @edit="open('commencement')"
        class="mb-1"
      >
        <div class="text-label">Commencement</div>
        <div v-if="local.commencement">
          {{ commencementSummary }}
        </div>
        <div v-else>
          <chip-required
            :class="{ shake: shake }"
            @click="open('commencement')"
          />
          <div class="text-no-result text-left">
            Click 'Edit' on the right to specify when your attorneys powers are
            to begin.
          </div>
        </div>
      </section-item>

      <section-item
        @edit="open('personal-terms')"
        class="mb-1"
      >
        <div class="text-label">
          Terms and instructions for your attorney{{ attorneySuffix }}
        </div>
        <div
          v-if="!local.terms"
          class="text-no-result text-left"
        >
          You have not added any terms or instructions. Click 'Edit' on the
          right to add some.
        </div>
        <div
          v-else
          class="clamp-3"
        >
          {{ local.terms }}
        </div>
      </section-item>

      <section-item
        @edit="open('personal-notify-who')"
        class="mb-1"
      >
        <div class="text-label">
          Who to notify for personal (including health) matters
        </div>
        <div
          v-if="local.personalNotify.me"
          class="mb-1"
        >
          <v-icon
            color="primary"
            size="16"
            class="mx-1"
            >$tick</v-icon
          >Me
        </div>
        <div
          v-if="local.personalNotify.otherAttorneys"
          class="mb-1"
        >
          <v-icon
            color="primary"
            size="16"
            class="mx-1"
            >$tick</v-icon
          >Other attorneys
        </div>
        <div
          v-if="personalNotifyOther.length"
          class="mb-1"
        >
          <v-icon
            color="primary"
            size="16"
            class="mx-1"
            >$tick</v-icon
          >Other {{ personalNotifyOther.length === 1 ? ' person' : ' people' }}:
          <ul class="mt-2 ml-6">
            <li
              v-for="item in personalNotifyOther"
              :key="item.id"
              class="mb-1"
            >
              {{ item.name }}
              <chip-required
                v-if="!allRequiredFieldsComplete(item)"
                text="Information required"
                @click="openEditPerson(item.id)"
                :class="{ shake: shake }"
              />
            </li>
          </ul>
        </div>
        <div
          v-if="
            !personalNotifyOther.length &&
            !local.personalNotify.me &&
            !local.personalNotify.otherAttorneys
          "
          class="text-no-result text-left"
        >
          You have not selected anyone to be notified. Click 'Edit' on the right
          to add someone.
        </div>
      </section-item>

      <section-item
        @edit="open('personal-notify-what')"
        class="mb-1"
      >
        <div class="text-label">
          What to notify for personal (including health) matters
        </div>
        <ul
          v-if="personalNotifyWhat.length"
          class="mt-2"
        >
          <li
            v-for="(item, index) in personalNotifyWhat"
            :key="index"
            class="mb-1"
          >
            {{ item }}
          </li>
        </ul>
        <div v-else>
          <div class="text-no-result text-left">
            <p>
              You have not provided any information. Click 'Edit' on the right
              to do so.
            </p>
          </div>
        </div>
      </section-item>

      <section-item
        @edit="open('financial-notify-who')"
        class="mb-1"
      >
        <div class="text-label">Who to notify for financial matters</div>
        <div
          v-if="local.financialNotify.me"
          class="mb-1"
        >
          <v-icon
            color="primary"
            size="16"
            class="mx-1"
            >$tick</v-icon
          >Me
        </div>
        <div
          v-if="local.financialNotify.otherAttorneys"
          class="mb-1"
        >
          <v-icon
            color="primary"
            size="16"
            class="mx-1"
            >$tick</v-icon
          >Other attorneys
        </div>
        <div
          v-if="financialNotifyOther.length"
          class="mb-1"
        >
          <v-icon
            color="primary"
            size="16"
            class="mx-1"
            >$tick</v-icon
          >Other
          {{ financialNotifyOther.length === 1 ? ' person' : ' people' }}:
          <ul class="mt-2 ml-6">
            <li
              v-for="item in financialNotifyOther"
              :key="item.id"
              class="mb-1"
            >
              {{ item.name }}
              <chip-required
                v-if="!allRequiredFieldsComplete(item)"
                text="Information required"
                @click="openEditPerson(item.id)"
                :class="{ shake: shake }"
              />
            </li>
          </ul>
        </div>
        <div
          v-if="
            !financialNotifyOther.length &&
            !local.financialNotify.me &&
            !local.financialNotify.otherAttorneys
          "
          class="text-no-result text-left"
        >
          You have not selected anyone to be notified. Click 'Edit' on the right
          to add someone.
        </div>
      </section-item>

      <section-item
        @edit="open('financial-notify-what')"
        class="mb-1"
      >
        <div class="text-label">What to notify for financial matters</div>
        <ul
          v-if="financialNotifyWhat.length"
          class="mt-2"
        >
          <li
            v-for="(item, index) in financialNotifyWhat"
            :key="index"
            class="mb-1"
          >
            {{ item }}
          </li>
        </ul>
        <div v-else>
          <div class="text-no-result text-left">
            <p>
              You have not provided any information. Click 'Edit' on the right
              to add some.
            </p>
          </div>
        </div>
      </section-item>

      <section-item
        @edit="open('financial-notify-when')"
        class="mb-1"
      >
        <div class="text-label">When to notify for financial matters</div>
        <ul
          v-if="financialNotifyWhen.length"
          class="mt-2"
        >
          <li
            v-for="(item, index) in financialNotifyWhen"
            :key="index"
          >
            <p class="clamp-2 mb-1">{{ item }}</p>
          </li>
        </ul>
        <div v-else>
          <div class="text-no-result text-left">
            <p>
              You have not provided any information. Click 'Edit' on the right
              to add some.
            </p>
          </div>
        </div>
      </section-item>

      <dialog-full
        :open.sync="dialogOpen"
        title="Enduring Power of Attorney"
        :path="gaUrl"
      >
        <component
          v-if="openForm"
          :is="`dialog-${openForm}`"
          @close="close"
          @next="next"
        />
      </dialog-full>
    </div>
    <drawer
      title="Edit Person"
      :open.sync="openEdit"
    >
      <div class="pa-4">
        <slot name="form">
          <form-person
            v-if="openEdit"
            :id="editId"
            @close="openEdit = false"
            @next="openEdit = false"
            :editable="editable"
          />
        </slot>
      </div>
    </drawer>
  </div>
</template>

<script>
import { qld_epa_required } from '../../../../config/poaRequiredFields'
import poa from '../mixins/poa.js'
import { formatToAusCurrency } from '../utils'
import DialogAttorneys from './forms/epa/Attorneys.vue'
import DialogCommencement from './forms/epa/Commencement.vue'
import DialogFinancialNotifyWhat from './forms/epa/FinancialNotifyWhat.vue'
import DialogFinancialNotifyWhen from './forms/epa/FinancialNotifyWhen.vue'
import DialogFinancialNotifyWho from './forms/epa/FinancialNotifyWho.vue'
import DialogMatters from './forms/epa/Matters.vue'
import DialogPersonalNotifyWhat from './forms/epa/PersonalNotifyWhat.vue'
import DialogPersonalNotifyWho from './forms/epa/PersonalNotifyWho.vue'
import DialogPersonalTerms from './forms/epa/PersonalTerms.vue'
import DialogViewsWishesPreferences from './forms/epa/ViewsWishesPreferences.vue'

const storeName = 'poa_qld_epa'
const documentDir = 'qldEpa'

export default {
  name: 'PoaEnduringPowerOfAttorney',
  mixins: [poa(storeName)],
  components: {
    DialogFinancialNotifyWhen,
    DialogCommencement,
    DialogMatters,
    DialogFinancialNotifyWhat,
    DialogFinancialNotifyWho,
    DialogAttorneys,
    DialogPersonalNotifyWhat,
    DialogPersonalNotifyWho,
    DialogPersonalTerms,
    DialogViewsWishesPreferences,
  },
  data: () => ({
    editable: { address: true, phone: true, email: true },
    required: qld_epa_required,
    documentDir: documentDir,
  }),
  methods: {},
  computed: {
    attorneys() {
      return this.entities_people.filter((o) =>
        this[storeName].attorneys.includes(o.id)
      )
    },
    attorneySuffix() {
      return this.attorneys.length > 1 ? 's' : ''
    },
    personalNotifyOther() {
      return this.entities_people.filter((o) =>
        this[storeName].personalNotify.other.includes(o.id)
      )
    },
    personalNotifyWhat() {
      let notify = []
      if (this[storeName].personalNotify.what.writtenNotice)
        notify.push(
          'written notice that my attorney(s) intend to begin exercising power for a personal (including health) matter under this enduring power of attorney before exercising the power for the first time'
        )
      if (this[storeName].personalNotify.what.otherInformation)
        notify.push('other information')
      return notify
    },
    financialNotifyOther() {
      return this.entities_people.filter((o) =>
        this[storeName].financialNotify.other.includes(o.id)
      )
    },
    financialNotifyWhat() {
      let notify = []
      if (this[storeName].financialNotify.what.writtenNotice)
        notify.push(
          'written notice that my attorney(s) intend to begin exercising power for financial matters under this enduring power of attorney before exercising the power for the first time.'
        )
      if (this[storeName].financialNotify.what.allRecords)
        notify.push('all financial records and accounts')
      if (this[storeName].financialNotify.what.aboveAmount)
        notify.push(
          `records relating to transactions above: ${formatToAusCurrency(
            this[storeName].financialNotify.what.aboveAmount
          )}`
        )
      if (this[storeName].financialNotify.what.assets)
        notify.push('records and accounts for all assets')
      if (this[storeName].financialNotify.what.summaries)
        notify.push('summaries of income, expenditure and assets')
      if (this[storeName].financialNotify.what.plansAndAdvice)
        notify.push(
          'copies of financial management plans and financial advice obtained'
        )
      if (this[storeName].financialNotify.what.otherInformation)
        notify.push('other information')
      return notify
    },
    financialNotifyWhen() {
      let notify = []
      if (this[storeName].financialNotify.when.onRequest)
        notify.push('on request at any time by the nominated person')
      if (this[storeName].financialNotify.when.onTimeframe)
        notify.push(
          `on a regular timeframe: ${this[storeName].financialNotify.when.onTimeframe}`
        )
      if (this[storeName].financialNotify.when.onOther)
        notify.push(this[storeName].financialNotify.when.onOther)
      return notify
    },
    commencementSummary() {
      if (this.local.commencement === 'lack-capacity')
        return `Your financial attorneys power begins when you do not have capacity to make decisions for financial matters`
      if (this.local.commencement === 'immediately')
        return `Your financial attorneys power begins immediately.`
      if (this.local.commencement === 'other')
        return this.local.commencementOther
      return ``
    },
  },
}
</script>
