<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">Donees</h2>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Instructions</div>
          <p>Add up to 4 people as your donees.</p>
        </div>
      </notification-box>

      <h3 class="mb-4">Your donees:</h3>

      <transition
        name="component-fade"
        mode="out-in"
      >
        <div
          v-if="!localFormatted.donees.length"
          class="text-no-result"
          key="empty"
        >
          - Add at least one donee -
        </div>
        <div
          key="list"
          v-else
        >
          <transition-group
            name="component-fade"
            mode="out-in"
          >
            <item-person
              v-for="(item, index) in localFormatted.donees"
              :key="item + index"
              class="mb-2"
              :id="item"
              :editable="editable"
              type="person"
              @remove="removeDonee(index)"
            >
              <template v-slot:details="{ entity }">
                <div
                  v-if="entity.type === 'person'"
                  class="text-sm text-cutoff opacity-70 py-1"
                >
                  <span
                    v-if="!entity.address"
                    class="font-italic"
                    >- Address Required -</span
                  >
                  <span v-else>
                    {{ entity.address }}
                  </span>
                </div>
              </template>
            </item-person>
          </transition-group>
        </div>
      </transition>

      <div class="text-label my-4">Add an donee:</div>
      <div class="mb-2">
        <btn-add-large
          label="Add person"
          class="mb-2"
          @click="openDrawerPerson = true"
          @clickDisabled="disabledMessage()"
          :disabled="isMaximumSelected"
        />
      </div>
      <v-input
        :error-messages="
          showErrors.donees && errorMessages.donees
            ? errorMessages.donees
            : null
        "
      />
      <v-expand-transition>
        <div
          v-if="this.local.donees.length > 1"
          class="mb-4"
        >
          <h2 class="mb-4">
            How would you like to your donees to work together?
          </h2>
          <item-select
            v-for="(item, index) in appointmentOptions"
            :key="item.key"
            :value="item.key === localFormatted.appointment"
            @select="selectAppointmentOption(item.key)"
            :title="item.label"
            :text="item.text"
            :class="{ 'mb-2': index + 1 < appointmentOptions.length }"
          />
          <v-input
            :error-messages="
              showErrors.appointment && errorMessages.appointment
                ? errorMessages.appointment
                : null
            "
          />
        </div>
      </v-expand-transition>

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
    <drawer
      :open.sync="openDrawerPerson"
      title="Add donee"
    >
      <drawer-person-select
        v-if="openDrawerPerson"
        @select="addDonee"
        :exclude="excludePeople"
        :editable="{ ...editable, name: false }"
        :mandatory="['address']"
      />
    </drawer>
  </div>
</template>

<script>
import entitiesForms from '../../../mixins/entitiesForms.js'
import forms from '../../../mixins/forms.js'
import { appointmentOptions } from '../../epaLists'

const storeName = 'poa_sa_epa'
const documentId = 'poaSaEpa'

export default {
  name: 'AppointDonees',
  mixins: [
    forms(storeName, documentId),
    entitiesForms('donees', 'Maximum of 4 donees.'),
  ],
  data: () => ({
    showErrors: {
      donees: false,
      appointment: false,
      appointmentTermination: false,
    },
    editable: { address: true },
    appointmentOptions: appointmentOptions,
  }),
  methods: {
    addDonee(id) {
      this.setAllShowErrors(false)
      this.local.donees.push(id)
      this.openDrawerPerson = false
    },
    removeDonee(index) {
      this.setAllShowErrors(false)
      this.$delete(this.local.donees, index)
    },
    selectAppointmentOption(option) {
      this.setAllShowErrors(false)
      this.$set(this.local, 'appointment', option)
    },
  },
  computed: {
    excludePeople() {
      return [...this.childEntities, ...this.localFormatted.donees]
    },
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (!this.localFormatted.donees.length)
        msgs.donees = 'Select at least one donee.'
      if (this.localFormatted.donees.length > 4)
        msgs.donees = 'Maximum of 4 donees.'
      if (
        this.localFormatted.donees.length > 1 &&
        !this.localFormatted.appointment
      )
        msgs.appointment = 'Required'
      return msgs
    },
    localFormatted() {
      let appointment = this.local?.appointment || null
      let appointmentOption = appointment
        ? this.appointmentOptions.find((o) => o.key === appointment) || null
        : null
      return {
        donees: this.local.donees || [],
        appointment:
          this.local.donees.length <= 1
            ? null
            : appointmentOption
              ? appointment
              : null,
      }
    },
  },
}
</script>
