<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">
        Conditions and instructions
        <span class="optional">(optional)</span>
      </h2>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Instructions</div>
          <p>
            Your attorney{{ multipleAttorneys ? 's are' : ' is' }} required to
            consider any conditions and/or instructions that you specify when
            making decisions for you. You do not have to place conditions or
            give instructions unless you want to.
          </p>
          <p>
            The exercise of power under this enduring power of attorney is
            subject to the conditions and/or instructions you set out below.
          </p>
        </div>
      </notification-box>

      <div v-if="hasFinancialAttorney || possiblyHasFinancialAttorney">
        <h3 class="mb-4">
          Conflict transactions
          <span class="optional">(optional)</span>
        </h3>
        <div
          v-if="possiblyHasFinancialAttorney"
          class="only-if-msg"
        >
          Only fill in this section if you have specified some financial matters
          for your attorney{{ attorneySuffix }} to act on.
        </div>
        <p>
          Sometimes there may be a conflict between the duty of your attorney{{
            attorneySuffix
          }}
          to you and an interest of their own, or of a relative, business
          associate or close friend. You can authorise (give permission) for
          your attorney to enter into transaction(s) even if there is a conflict
          of interest.
        </p>
        <p>
          <strong>
            I authorise my attorney{{ attorneySuffix }} to enter into the
            following conflict transaction(s):
          </strong>
        </p>
        <p
          class="definition-link"
          @click="openExample('conflict')"
        >
          See example
        </p>
        <v-textarea
          :rules="[maxLengthRule]"
          v-bind="textAreaFieldGrow"
          v-model="local.conflictTransactions"
          class="mb-6"
          :error-messages="
            showErrors.conflictTransactions &&
            errorMessages.conflictTransactions
              ? errorMessages.conflictTransactions
              : null
          "
        />

        <h3 class="mb-4">
          Gifts
          <span class="optional">(optional)</span>
        </h3>
        <div
          v-if="possiblyHasFinancialAttorney"
          class="only-if-msg"
        >
          Only fill in this section if you have specified some financial matters
          for your attorney{{ attorneySuffix }} to act on.
        </div>
        <p>
          An attorney{{ allMatters ? '' : ' for financial matters' }} can use
          your money or other financial assets to give a gift or donation. Gifts
          must be of a seasonal nature or for a special event and be made to
          your relatives or close friends. An attorney can also give a gift to
          themselves, their relatives, close friends or organisations with which
          they have a connection. The donation must be the type of donation made
          when you had capacity or that you might reasonably be expected to
          make. All gifts and donations must be reasonable in the circumstances,
          particularly having regard to your financial situation.
        </p>
        <p>
          <strong>
            Specify any conditions or restrictions that you want to place on the
            making of gifts or donations.
          </strong>
        </p>
        <p
          class="definition-link"
          @click="openExample('gifts')"
        >
          See example
        </p>
        <v-textarea
          :rules="[maxLengthRule]"
          v-bind="textAreaFieldGrow"
          v-model="local.gifts"
          class="mb-6"
          :error-messages="
            showErrors.gifts && errorMessages.gifts ? errorMessages.gifts : null
          "
        />

        <h3 class="mb-4">
          Maintenance of your dependants
          <span class="optional">(optional)</span>
        </h3>
        <div
          v-if="possiblyHasFinancialAttorney"
          class="only-if-msg"
        >
          Only fill in this section if you have specified some financial matters
          for your attorney{{ attorneySuffix }} to act on.
        </div>
        <p>
          You can specify in your enduring power of attorney if you want your
          attorney{{ attorneySuffix
          }}{{ allMatters ? '' : ' for financial matters' }} to use your money
          or other financial assets to provide for the needs of one or more of
          your dependants (for example, one of your children). The amount made
          available by your attorney to maintain your dependants must not be
          more than what is reasonable having regard to all the circumstances,
          in particular your financial circumstances, unless you specify
          otherwise in your enduring power of attorney.
        </p>
        <p>
          <strong>
            Specify if you want to authorise your attorney{{ attorneySuffix
            }}{{ allMatters ? '' : ' for financial matters' }}
            to provide for the maintenance of your dependant(s) from your money
            or other financial assets and, if so, whether you want to authorise
            an amount that is more than what is reasonable in the circumstances.
          </strong>
        </p>
        <p
          class="definition-link"
          @click="openExample('maintenance')"
        >
          See example
        </p>
        <v-textarea
          :rules="[maxLengthRule]"
          v-bind="textAreaFieldGrow"
          v-model="local.maintenanceOfDependants"
          class="mb-6"
          :error-messages="
            showErrors.maintenanceOfDependants &&
            errorMessages.maintenanceOfDependants
              ? errorMessages.maintenanceOfDependants
              : null
          "
        />
      </div>

      <h3 class="mb-4">
        Payments to attorney(s)
        <span class="optional">(optional)</span>
      </h3>
      <p>
        An attorney is not allowed to be paid to act as your attorney, unless
        payment is authorised in the enduring power of attorney or by law.
      </p>
      <p>
        <strong>
          You can authorise your attorney{{ attorneySuffix }} to be paid by
          specifying below how your attorney{{ attorneySuffix }}
          {{ multipleAttorneys ? 'are' : 'is' }} to be paid and any limits on
          how much they can be paid.
        </strong>
      </p>
      <p
        class="definition-link"
        @click="openExample('payment')"
      >
        See example
      </p>
      <v-textarea
        :rules="[maxLengthRule]"
        v-bind="textAreaFieldGrow"
        v-model="local.paymentToAttorneys"
        class="mb-6"
        :error-messages="
          showErrors.paymentToAttorneys && errorMessages.paymentToAttorneys
            ? errorMessages.paymentToAttorneys
            : null
        "
      />

      <h3 class="mb-4">
        Additional conditions or instructions
        <span class="optional">(optional)</span>
      </h3>
      <p>
        You may want to set out additional conditions and/or instructions to
        guide your attorney{{ attorneySuffix }}. You may also want to specify a
        person(s) to be notified by the attorney, when the attorney starts
        acting for you, when you no longer have decision making capacity.
      </p>
      <p>
        <strong> Enter conditions and instructions below. </strong>
      </p>
      <p
        class="definition-link"
        @click="openExample('additional')"
      >
        See example
      </p>
      <v-textarea
        :rules="[maxLengthRule]"
        v-bind="textAreaFieldGrow"
        v-model="local.additionalConditions"
        class="mb-6"
        :error-messages="
          showErrors.additionalConditions && errorMessages.additionalConditions
            ? errorMessages.additionalConditions
            : null
        "
      />

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
    <drawer
      :open.sync="showExamples"
      title="Examples"
      class="faq-drawer"
    >
      <div
        v-if="showExampleConflict"
        class="pa-4"
      >
        <div class="text-lg font-weight-bold mb-4">Conflict transactions</div>
        <p>
          For example, you may wish to authorise your attorney to purchase one
          of your assets that you are no longer able to use at a price that your
          attorney determines to be appropriate.
        </p>
        <p>Sample wording for the above example might be as follows:</p>
        <p>
          <b>
            I authorise my attorneys to enter into the following conflict
            transaction(s):
          </b>
        </p>
        <p class="example-text">
          In the event that I no longer have a valid driver licence and am
          unlikely to obtain a future driver licence, my attorneys may purchase
          my motor vehicle with registration number ABC123 at a price that my
          attorneys deem to be reasonable.
        </p>
      </div>
      <div
        v-if="showExampleGifts"
        class="pa-4"
      >
        <div class="text-lg font-weight-bold mb-4">Gifts</div>
        <p>
          For example, you may authorise your attorney(s) to purchase a gift for
          a particular person or people (e.g., your partner and/or children)
          every year on the occasion of their birthday and may specify the
          amount of money to be spent on such gifts.
        </p>
        <p>Sample wording for the above example might be as follows:</p>
        <p class="example-text">
          My attorney(s) are authorised to purchase a gift for each of my
          grandchildren every year on the occasion of their birthday to the
          value of no more than $100.00 (or current equivalent taking into
          account CPI) per year.
        </p>
      </div>
      <div
        v-if="showExampleMaintenance"
        class="pa-4"
      >
        <div class="text-lg font-weight-bold mb-4">
          Maintenance of your dependants
        </div>
        <p>
          For example, you may authorise your attorney(s) to use your financial
          assets to pay for private college tuition for your dependants.
        </p>
        <p>Sample wording for the above example might be as follows:</p>
        <p class="example-text">
          My attorney(s) are authorised to provide for the maintenance of my
          children from my money or other financial assets, including fees for
          private college tuition not to exceed the sum of $40,000.00 (or
          current equivalent taking into account CPI) per child per year.
        </p>
      </div>
      <div
        v-if="showExamplePayment"
        class="pa-4"
      >
        <div class="text-lg font-weight-bold mb-4">Payments to attorney(s)</div>
        <p>
          For example, you may authorise your attorney(s) to be paid a certain
          amount for each year that they act as attorney, such payment to be
          made, for example, at the end of each such calendar year or financial
          year.
        </p>
        <p>Sample wording for the above example might be as follows:</p>
        <p class="example-text">
          My attorney(s) are authorised to be paid the sum of $1,000.00 (or
          current equivalent taking into account CPI) per year for each full
          year that they act as my attorney, such payment to be made at the end
          of each calendar year.
        </p>
      </div>
      <div
        v-if="showExampleAdditional"
        class="pa-4"
      >
        <div class="text-lg font-weight-bold mb-4">
          Additional conditions or instructions
        </div>
        <p>
          For example, you may wish to require that your attorney notify a
          family member, trusted friend or professional adviser before
          exercising the power.
        </p>
        <p>Sample wording for the above example might be as follows:</p>
        <p class="example-text">
          My attorney(s) must notify my friend Brian Green before exercising
          their powers for the first time.
        </p>
      </div>
    </drawer>
  </div>
</template>

<script>
import forms from '../../../mixins/forms.js'
import { isEmptyTrimmed, trimIfExists } from '../../../utils'

const storeName = 'poa_vic_epa'
const documentId = 'poaVicEpa'

export default {
  name: 'ConditionsAndLimitations',
  mixins: [forms(storeName, documentId)],
  data: () => ({
    showErrors: {
      conditions: false,
    },
    showTextbox: false,
    showExamples: false,
    showExampleConflict: false,
    showExampleGifts: false,
    showExampleMaintenance: false,
    showExamplePayment: false,
    showExampleAdditional: false,
  }),
  mounted() {
    this.showTextbox = !!this.local.conditions
  },
  methods: {
    setShowTextbox(value) {
      this.setAllShowErrors(false)
      this.showTextbox = value
    },
    async save() {
      this.$store.dispatch('account/save_poa', {
        id: documentId,
        data: {
          conflictTransactions: trimIfExists(
            this.localFormatted.conflictTransactions
          ),
          gifts: trimIfExists(this.localFormatted.gifts),
          maintenanceOfDependants: trimIfExists(
            this.localFormatted.maintenanceOfDependants
          ),
          paymentToAttorneys: trimIfExists(
            this.localFormatted.paymentToAttorneys
          ),
          additionalConditions: trimIfExists(
            this.localFormatted.additionalConditions
          ),
        },
      })
    },
    openExample(type) {
      this.showExampleConflict = false
      this.showExampleGifts = false
      this.showExampleMaintenance = false
      this.showExamplePayment = false
      this.showExampleAdditional = false
      this.showExamples = true
      this.showExampleConflict = type === 'conflict'
      this.showExampleGifts = type === 'gifts'
      this.showExampleMaintenance = type === 'maintenance'
      this.showExamplePayment = type === 'payment'
      this.showExampleAdditional = type === 'additional'
    },
  },
  computed: {
    multipleAttorneys() {
      return this.local.attorneys.length > 1
    },
    attorneySuffix() {
      return this.multipleAttorneys ? 's' : ''
    },
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (this.showTextbox && isEmptyTrimmed(this.localFormatted.conditions))
        msgs.conditions = 'Please enter your conditions or restrictions.'
      return msgs
    },
    hasFinancialAttorney() {
      return this.local.matters === 'financial' || this.local.matters === 'all'
    },
    possiblyHasFinancialAttorney() {
      return this.local.matters === 'specified'
    },
    allMatters() {
      return this.local.matters === 'all'
    },
    localFormatted() {
      return {
        conflictTransactions: this.local.conflictTransactions || null,
        gifts: this.local.gifts || null,
        maintenanceOfDependants: this.local.maintenanceOfDependants || null,
        paymentToAttorneys: this.local.paymentToAttorneys || null,
        additionalConditions: this.local.additionalConditions || null,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.only-if-msg {
  font-size: 0.9rem;
  font-style: italic;
  background: #f1f1f1;
  margin-bottom: 1rem;
  padding: 0.6rem 1rem;
}
</style>
